import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MixpanelEvent } from '@global/enums/mixpanel-event.enum';
import { LoadingIndicatorService } from '@global/modules/loading-indicator/services/loading-indicator.service';
import { AuthService } from '@global/services/auth.service';
import { MixpanelService } from '@global/services/mixpanel.service';
import {
  acceptQuoteRetryInterface,
  addPartsRetryInterface,
  createSoPackageRetryInterface,
  deleteSoPackageRetryInterface,
  generateCostEstimateRetryInterface,
  getCourierRatesRetryInterface,
  getRolloutPollInterface,
  ReMessageArrayInterface,
  SoDetailsPollInterface,
  SoRelatedObjectsInterface,
  updateCourierRatesRetryInterface,
  updateCRAddressToFreightRetryInterface,
  updateFinalDeliveryAddressesRetryInterface,
  updateFreightAddressRetryRolloutInterface,
  updateFreightAddressRetryShipmentInterface,
  updatePartsRetryInterface,
  updateShippingFeesRetryInterface,
  updateSoPackageRetryInterface,
  updateTaskRetryInterface,
} from '@shared/interfaces/re-polling.interface';
import {
  AddPartsResponseItem,
  QuoteDataService,
  ShipmentOrderDataService,
  ShipmentOrderPackageDataService,
  ShipmentOrderRelations,
  TasksDataService,
} from '@tecex-api/data';
import { AddPartsResponse } from '@tecex-api/data/model/addPartsResponse';
import { CourierRate } from '@tecex-api/data/model/courierRate';
import { Rollout } from '@tecex-api/data/model/rollout';
import { ShipmentOrder } from '@tecex-api/data/model/shipmentOrder';
import { ShipmentOrderPackage } from '@tecex-api/data/model/shipmentOrderPackage';
import { Success } from '@tecex-api/data/model/success';
import { combineLatest, mergeMap, Observable, of, retry, switchMap } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';
import { sanitizePartPayload } from '@shared/helpers/sanitize-part-payload.helper';

//Global polling settings. Can be overwritten when calling the polling function
const DEFAULT_POLL_INTERVAL = 2500;
const DEFAULT_POLL_COUNT = 20;
let criticalError = false;

@Injectable({
  providedIn: 'root',
})
export class RePollService {
  constructor(
    private readonly authService: AuthService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly quoteDataService: QuoteDataService,
    private readonly shipmentOrderDataService: ShipmentOrderDataService,
    private readonly shipmentOrderPackageDataService: ShipmentOrderPackageDataService,
    private readonly router: Router,
    private readonly mixpanelService: MixpanelService,
    private readonly tasksDataService: TasksDataService
  ) {}

  private readonly routerChange$ = this.router.events.pipe(
    filter((event) => {
      return event instanceof NavigationStart && !this.router.url.includes('#task');
    })
  );

  public pollSoDetails$(SoDetailsPollRequest: SoDetailsPollInterface): Observable<ShipmentOrder> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.shipmentOrderDataService
      .getShipmentOrder({
        Accesstoken: SoDetailsPollRequest.accessToken,
        AccountID: SoDetailsPollRequest.accountId,
        ContactID: SoDetailsPollRequest.contactId,
        SOID: SoDetailsPollRequest.shipmentOrderId,
      })
      .pipe(
        map((resp) => {
          count++;
          if (!resp?.RE_TriggerEvent__c || resp?.RE_CriticalError__c) {
            criticalError = !!resp?.RE_CriticalError__c;
            return resp;
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: SoDetailsPollRequest.pollCount ? SoDetailsPollRequest.pollCount : DEFAULT_POLL_COUNT,
          delay: SoDetailsPollRequest.pollInterval ? SoDetailsPollRequest.pollInterval : DEFAULT_POLL_INTERVAL,
        }),
        takeUntil(this.routerChange$),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'SODetails', count, criticalError });
        })
      );
  }

  public pollDetailAndRelatedObjects$(SoDetailsPollRequest: SoDetailsPollInterface): Observable<[ShipmentOrder, ShipmentOrderRelations]> {
    return this.pollSoDetails$(SoDetailsPollRequest).pipe(
      mergeMap((resp) => {
        return combineLatest([
          of(resp),
          this.shipmentOrderDataService.getShipmentOrderRelations({
            Accesstoken: SoDetailsPollRequest.accessToken,
            AccountID: SoDetailsPollRequest.accountId,
            SOID: SoDetailsPollRequest.shipmentOrderId,
          }),
        ]);
      }),
      map(([resp, resp2]) => {
        return [resp, resp2];
      })
    );
  }

  public pollSoRelatedObjects$(SoRelatedObjectsPollRequest: SoRelatedObjectsInterface): Observable<ShipmentOrderRelations> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.shipmentOrderDataService
      .getShipmentOrderRelations({
        Accesstoken: SoRelatedObjectsPollRequest.accessToken,
        AccountID: SoRelatedObjectsPollRequest.accountId,
        SOID: SoRelatedObjectsPollRequest.shipmentOrderId,
      })
      .pipe(
        map((resp) => {
          count++;
          if (!resp?.ShipmentOrder?.RE_TriggerEvent__c || !resp?.ShipmentOrder?.RE_CriticalError__c) {
            criticalError = !!resp?.ShipmentOrder?.RE_CriticalError__c;
            return resp;
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: SoRelatedObjectsPollRequest.pollCount ? SoRelatedObjectsPollRequest.pollCount : DEFAULT_POLL_COUNT,
          delay: SoRelatedObjectsPollRequest.pollInterval ? SoRelatedObjectsPollRequest.pollInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, {
            screen_name: this.router.url,
            api: 'SOAllRelatedInfo',
            count,
            criticalError,
          });
        })
      );
  }

  public pollRoDetails$(getRolloutPollRequest: getRolloutPollInterface): Observable<Rollout> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    // @ts-ignore
    return this.shipmentOrderDataService
      .getRollout({
        Accesstoken: getRolloutPollRequest.accessToken,
        ROID: getRolloutPollRequest.rolloutId,
      })
      .pipe(
        map((resp) => {
          count++;
          if (!resp?.RollOut?.RO_RE_TriggerEvent || resp?.RollOut?.RO_RE_CriticalError) {
            return resp;
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: getRolloutPollRequest.pollCount ? getRolloutPollRequest.pollCount : DEFAULT_POLL_COUNT,
          delay: getRolloutPollRequest.pollInterval ? getRolloutPollRequest.pollInterval : DEFAULT_POLL_INTERVAL,
        }),
        takeUntil(this.routerChange$),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'GetRODetails', count, criticalError });
        })
      );
  }

  public acceptQuoteRetry$(acceptQuoteRetryRequest: acceptQuoteRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .acceptQuote({
        Accesstoken: acceptQuoteRetryRequest.accessToken,
        AccountID: acceptQuoteRetryRequest.accountId,
        SO: acceptQuoteRetryRequest.soArray,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: acceptQuoteRetryRequest.retryCount ? acceptQuoteRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: acceptQuoteRetryRequest.retryInterval ? acceptQuoteRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'AcceptCE', count });
        })
      );
  }

  //This api seems to be failing due to some kind of null Pointer
  public updateSoPackageRetry$(updateSoPackageRetryRequest: updateSoPackageRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.shipmentOrderPackageDataService
      .updateShipmentOrderPackages({
        Accesstoken: updateSoPackageRetryRequest.user.accessToken,
        AccountID: updateSoPackageRetryRequest.user.accountId,
        ContactID: updateSoPackageRetryRequest.user.contactId,
        SOP: updateSoPackageRetryRequest.payload,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: updateSoPackageRetryRequest.retryCount ? updateSoPackageRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateSoPackageRetryRequest.retryInterval ? updateSoPackageRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'UpdateSOPackage', count });
        })
      );
  }

  public updateShippingFeesRetry$(updateShippingFeesRetryRequest: updateShippingFeesRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .updateShippingFees({
        Accesstoken: updateShippingFeesRetryRequest.accessToken,
        SOID: updateShippingFeesRetryRequest.SOID,
        liabilitycoverfee: updateShippingFeesRetryRequest.liabilitycoverfee,
        shippingfee: updateShippingFeesRetryRequest.shippingfee,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: updateShippingFeesRetryRequest.retryCount ? updateShippingFeesRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateShippingFeesRetryRequest.retryInterval ? updateShippingFeesRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'updateShippingFees', count });
        })
      );
  }

  public updateFreightAddressRetryShipment$(
    updateFreightAddressRetryRequest: updateFreightAddressRetryShipmentInterface
  ): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .updateFreightAddress({
        Accesstoken: updateFreightAddressRetryRequest.accessToken,
        FreightList: [
          {
            FRID: updateFreightAddressRetryRequest.FRID,
            PickupaddressID: updateFreightAddressRetryRequest.pickupAddressId,
          },
        ],
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: updateFreightAddressRetryRequest.retryCount ? updateFreightAddressRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateFreightAddressRetryRequest.retryInterval ? updateFreightAddressRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, {
            screen_name: this.router.url,
            api: 'NCPupdateFreightPickupAddress',
            count,
          });
        })
      );
  }

  public updateFreightAddressRetryRollout$(
    updateFreightAddressRetryRequest: updateFreightAddressRetryRolloutInterface
  ): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .updateFreightAddress({
        Accesstoken: updateFreightAddressRetryRequest.accessToken,
        FreightList: updateFreightAddressRetryRequest.freightList,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Success) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: updateFreightAddressRetryRequest.retryCount ? updateFreightAddressRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateFreightAddressRetryRequest.retryInterval ? updateFreightAddressRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, {
            screen_name: this.router.url,
            api: 'NCPupdateFreightPickupAddressRollout',
            count,
          });
        })
      );
  }

  public updateFinalDeliveryAddressesRetry$(
    updateFinalDeliveryAddressesRetryRequest: updateFinalDeliveryAddressesRetryInterface
  ): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.pollSoRelatedObjects$({
      accessToken: updateFinalDeliveryAddressesRetryRequest.accessToken,
      accountId: updateFinalDeliveryAddressesRetryRequest.accountId,
      shipmentOrderId: updateFinalDeliveryAddressesRetryRequest.shipmentOrderId,
    }).pipe(
      switchMap((related) => {
        updateFinalDeliveryAddressesRetryRequest.finalDelivery.forEach((newDeliveries) => {
          if (related.FinalDeliveries) {
            related.FinalDeliveries.forEach((relatedDeliveries) => {
              if (newDeliveries.FinalDestinationAddressID === relatedDeliveries.ShiptoAddressID) {
                newDeliveries.FDAId = relatedDeliveries.Id;
              }
            });
          }
        });
        return this.quoteDataService
          .updateFinalDeliveryAddresses({
            Accesstoken: updateFinalDeliveryAddressesRetryRequest.accessToken,
            SOID: updateFinalDeliveryAddressesRetryRequest.shipmentOrderId,
            Final_Delivery: updateFinalDeliveryAddressesRetryRequest.finalDelivery,
          })
          .pipe(
            map((resp) => {
              count++;
              if (resp.Success) {
                return resp;
              } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
                throw new Error('Need to retry');
              }
              throw new Error('Need to retry');
            }),
            retry({
              count: updateFinalDeliveryAddressesRetryRequest.retryCount
                ? updateFinalDeliveryAddressesRetryRequest.retryCount
                : DEFAULT_POLL_COUNT,
              delay: updateFinalDeliveryAddressesRetryRequest.retryInterval
                ? updateFinalDeliveryAddressesRetryRequest.retryInterval
                : DEFAULT_POLL_INTERVAL,
            }),
            finalize(() => {
              this.mixpanelService.track(MixpanelEvent.APIAction, {
                screen_name: this.router.url,
                api: 'NCPAddorremoveFDAddress',
                count,
              });
            })
          );
      })
    );
  }

  public updatePartsRetry$(updatePartsRetryRequest: updatePartsRetryInterface): Observable<Array<AddPartsResponseItem>> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .updateParts({
        Accesstoken: updatePartsRetryRequest.accesstoken,
        AccountID: updatePartsRetryRequest.accountId,
        Parts: sanitizePartPayload(updatePartsRetryRequest.parts),
        SOID: updatePartsRetryRequest.shipmentOrderId,
      })
      .pipe(
        map((resp: ReMessageArrayInterface | AddPartsResponseItem[]) => {
          count++;
          if (Array.isArray(resp)) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: updatePartsRetryRequest.retryCount ? updatePartsRetryRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updatePartsRetryRequest.retryInterval ? updatePartsRetryRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'NCPEditParts', count });
        })
      );
  }

  public addPartsRetry$(addPartsRequest: addPartsRetryInterface): Observable<AddPartsResponse> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .addParts({
        Accesstoken: addPartsRequest.accesstoken,
        AccountID: addPartsRequest.accountId,
        Parts: sanitizePartPayload(addPartsRequest.parts),
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: addPartsRequest.retryCount ? addPartsRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: addPartsRequest.retryInterval ? addPartsRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'NCPAddParts', count });
        })
      );
  }

  public createSoOrderPackageRetry$(createPackageRequest: createSoPackageRetryInterface): Observable<Array<ShipmentOrderPackage>> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.shipmentOrderPackageDataService
      .createShipmentOrderPackages({
        Accesstoken: createPackageRequest.accessToken,
        AccountID: createPackageRequest.accountId,
        ShipmentOrder_Packages: createPackageRequest.soPackages,
      })
      .pipe(
        map((resp: ReMessageArrayInterface | ShipmentOrderPackage[]) => {
          count++;
          if (Array.isArray(resp)) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: createPackageRequest.retryCount ? createPackageRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: createPackageRequest.retryInterval ? createPackageRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'CreateSOOrderPackage', count });
        })
      );
  }

  public deleteSOPackageRetry$(deleteSoPackageRequest: deleteSoPackageRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.shipmentOrderPackageDataService
      .deleteShipmentOrderPackages({
        Accesstoken: deleteSoPackageRequest.accessToken,
        SOP: deleteSoPackageRequest.soPackages,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: deleteSoPackageRequest.retryCount ? deleteSoPackageRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: deleteSoPackageRequest.retryInterval ? deleteSoPackageRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'DeleteSOPackage', count });
        })
      );
  }

  public updateCourierRatesRetry$(updateCourierRatesRequest: updateCourierRatesRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .updateCourierRates({
        Accesstoken: updateCourierRatesRequest.accessToken,
        CRID: updateCourierRatesRequest.courierRateId,
        Status: updateCourierRatesRequest.status,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: updateCourierRatesRequest.retryCount ? updateCourierRatesRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateCourierRatesRequest.retryInterval ? updateCourierRatesRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'UpdateCourierrates', count });
        })
      );
  }

  public getCourierRatesRetry$(getCourierRatesRequest: getCourierRatesRetryInterface): Observable<Array<CourierRate>> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .getCourierRates({
        Accesstoken: getCourierRatesRequest.accessToken,
        SOID: getCourierRatesRequest.shipmentOrderId,
        FRID: getCourierRatesRequest.freightRequestId,
      })
      .pipe(
        map((resp: ReMessageArrayInterface | ShipmentOrderPackage[]) => {
          count++;
          if (Array.isArray(resp)) {
            return resp;
          } else if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          throw new Error('Need to retry');
        }),
        retry({
          count: getCourierRatesRequest.retryCount ? getCourierRatesRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: getCourierRatesRequest.retryInterval ? getCourierRatesRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'GetCourierrates', count });
        })
      );
  }

  public generateCostEstimatePdfRetry$(generateCostEstimateRequest: generateCostEstimateRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .generateCostEstimate({
        Accesstoken: generateCostEstimateRequest.accessToken,
        Identifier: 0,
        RecordID: generateCostEstimateRequest.shipmentOrderId,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: generateCostEstimateRequest.retryCount ? generateCostEstimateRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: generateCostEstimateRequest.retryInterval ? generateCostEstimateRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'NCPDownlodCostEstimates', count });
        })
      );
  }

  public updateTaskRetry$(updateTaskRequest: updateTaskRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.tasksDataService
      .updateTask({
        TaskID: updateTaskRequest.taskId,
        AccessToken: updateTaskRequest.accessToken,
        data: updateTaskRequest.taskData,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: updateTaskRequest.retryCount ? updateTaskRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateTaskRequest.retryInterval ? updateTaskRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'NCPUpdateTask', count });
        })
      );
  }

  public updateCRAddressToFreightRetry$(updateCRAddressToFreightRequest: updateCRAddressToFreightRetryInterface): Observable<Success> {
    this.mixpanelService.timeEvent(MixpanelEvent.APIAction);
    let count = 0;
    return this.quoteDataService
      .updateCRAddressToFreight({
        Accesstoken: updateCRAddressToFreightRequest.accessToken,
        FreightID: updateCRAddressToFreightRequest.freightId,
        CRAddressId: updateCRAddressToFreightRequest.crAddressId,
      })
      .pipe(
        map((resp) => {
          count++;
          if (resp.Response?.ReMessage === 'Locked_Shipment_Failure') {
            throw new Error('Need to retry');
          }
          return resp;
        }),
        retry({
          count: updateCRAddressToFreightRequest.retryCount ? updateCRAddressToFreightRequest.retryCount : DEFAULT_POLL_COUNT,
          delay: updateCRAddressToFreightRequest.retryInterval ? updateCRAddressToFreightRequest.retryInterval : DEFAULT_POLL_INTERVAL,
        }),
        finalize(() => {
          this.mixpanelService.track(MixpanelEvent.APIAction, { screen_name: this.router.url, api: 'NCPCRAddresstoFreight', count });
        })
      );
  }
}
