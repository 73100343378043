<div *ngIf="isLoading; else messageThread" class="d-flex align-items-center justify-content-center h-100">
  <app-loading-indicator></app-loading-indicator>
</div>
<ng-template #messageThread>
  <app-sendbird-message-thread
    [messageThread]="messageThreadDetails"
    [taggedRoles]="taggedRoles"
    [sendbirdMessage]="channelAndMessage?.messages"
    [sendbirdchannel]="channelAndMessage?.channel"
    [sendbirdparticiapant]="channelAndMessage?.channelInfo?.users"
    [isguestUserEnabled]="channelAndMessage?.channelInfo?.guestUserEnabled"
    [parentObjectDynamicValue]="channelAndMessage.channelInfo?.parentObject"
    [master_task_id]="data.payload.shipment?.master_task_id"
    [teamMembers]="teamMembers"
    [shipmentOrderPeople]="shipmentOrderPeople"
    [sendingMessage]="isSending"
    [payloadOfDialog]="this.data.payload"
    [defaultParticipantSectionFlag]="true"
    [selectedShipmentId]="selectedShipmentId$ | async"
    [selectedShipmentAccountId]="selectedShipmentAccountId$ | async"
    [selectedTaskAccountId]="selectedTaskAccountId$ | async"
    [sendBirdChannelInfo]="channelAndMessage?.channelInfo"
    (send)="onMessageSend($event)"
    (outSideClick)="onOutSideClick($event)"
    (S3fileMessageDownload)="downloadS3Message($event)"
    (reload)="reload()"
  ></app-sendbird-message-thread>
</ng-template>
