import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import isNil from 'lodash/isNil';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { NgIf, AsyncPipe } from '@angular/common';
import * as fromThirdParty from '../../../../../../../projects/tecex/src/app/modules/third-party/reducers';
import { MixpanelEvent } from '../../../../enums/mixpanel-event.enum';
import { MessagePayload } from '../../../../interfaces/messages/message-payload.interface';
import { MessageThreadDetails } from '../../../../interfaces/messages/message-thread-details.interface';
import { ShipmentOrderPerson } from '../../../../interfaces/shipment-order-person.interface';
import { TeamMemberLists } from '../../../../interfaces/team-member-lists.interface';
import { AuthService } from '../../../../services/auth.service';
import { MixpanelService } from '../../../../services/mixpanel.service';
import { TeamMemberService } from '../../../../services/team-member.service';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { ChannelAndMessage, SendbirdService } from '../../../message-thread/services/sendbird-message.service';
import { MessageDialogDataService } from '../../services/message-dialog-data.service';
import { MessageDialogPayload } from '../../types/message-dialog-payload.interface';
import { SendbirdMessageThreadComponent } from '../../../message-thread/components/sendbird-message-thread/sendbird-message-thread.component';
import { LoadingIndicatorComponent } from '../../../loading-indicator/components/loading-indicator/loading-indicator.component';
import { CommonMessagesService } from '../../../common-messages/services/common-messages.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LoadingIndicatorComponent, SendbirdMessageThreadComponent, AsyncPipe],
})
export class MessageDialogComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line: variable-name
  private readonly _messageThreadDetails$ = new BehaviorSubject<MessageThreadDetails | undefined>(undefined);
  private readonly _channelAndMessage$ = new BehaviorSubject<ChannelAndMessage | undefined>(undefined);

  public get messageThreadDetails(): MessageThreadDetails | undefined {
    return this._messageThreadDetails$.getValue();
  }
  public set messageThreadDetails(messageThreadDetails: MessageThreadDetails) {
    this._messageThreadDetails$.next(messageThreadDetails);
  }

  public get channelAndMessage(): ChannelAndMessage | undefined {
    return this._channelAndMessage$.getValue();
  }
  public set channelAndMessage(channelAndMessage: ChannelAndMessage) {
    this._channelAndMessage$.next(channelAndMessage);
  }

  public teamMembers: TeamMemberLists = {
    taggable: [],
    mentionable: [],
  };
  public shipmentOrderPeople: ShipmentOrderPerson[] = [];
  public isLoading = false;
  public isSending = false;
  public taggedRoles;
  public selectedShipmentId$: Observable<string>;
  public selectedShipmentAccountId$: Observable<string>;
  public selectedTaskAccountId$: Observable<string>;
  private readonly destroyed$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public S3fileMessageDownload = new EventEmitter<any>();

  constructor(
    @Inject(DIALOG_DATA) public readonly data: DialogData<MessageDialogPayload, MessageThreadDetails>,
    private readonly messageDialogDataService: MessageDialogDataService,
    private readonly cdr: ChangeDetectorRef,
    private readonly teamMemberService: TeamMemberService,
    private readonly sendbirdSerive: SendbirdService,
    private readonly store$: Store<fromThirdParty.AppState>,
    private readonly authService: AuthService,
    private readonly mixpanelService: MixpanelService,
    private readonly router: Router,
    private readonly commonMessagesService: CommonMessagesService
  ) {}

  public ngOnInit(): void {
    this.taggedRoles =
      this.data.payload.taggedRoles && this.data.payload.taggedRoles.length > 0
        ? this.data.payload.taggedRoles
        : this.commonMessagesService.assignMessageRole();
    if (this.authService.thirdPartyFlag()) {
      this.selectedShipmentId$ = this.store$.select(fromThirdParty.selectSelectedShipmentId);
      this.selectedShipmentAccountId$ = this.store$.select(fromThirdParty.selectShipmentAccountId);
      this.selectedTaskAccountId$ = this.store$.select(fromThirdParty.selectTaskAccountId);
    }
    this.loadInitialData();
    this.startCaseDetailsPoll();
  }

  public reload(): void {
    this.ngOnInit();
  }

  private loadInitialData(): void {
    this.isLoading = true;
    forkJoin([
      this.messageDialogDataService.getInitialMessageThread$(this.data.payload),
      this.messageDialogDataService.getMessageThreadSendbird$(this.data.payload.id, this.data.payload),
      this.messageDialogDataService.getTeamMembers$(this.data.payload.shipment?.id, this.data.payload.teamMemberListType),
      this.teamMemberService.getShipmentOrderPeople$(this.data.payload.shipment?.id),
    ])
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(([messageThreadDetails, channelandmembers, teamMembers, shipmentOrderPeople]) => {
        this.messageThreadDetails = messageThreadDetails;
        this.channelAndMessage = channelandmembers;
        this.teamMembers = teamMembers;
        //console.log(shipmentOrderPeople);
        this.shipmentOrderPeople = shipmentOrderPeople;
        this.cdr.markForCheck();
        this.openMessageTrack();
      });
    this.registerEventHandlers();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private openMessageTrack(): void {
    const url = this.router.url;
    if (this.channelAndMessage?.channelInfo) {
      let screen_name: string;
      if (url === '/dashboard') {
        screen_name = '/dashboard';
      } else if (url.includes('dashboard#task')) {
        screen_name = '/dashboard/task';
      } else if (url.includes('shipments-list') && !url.includes('task')) {
        screen_name = '/shipments-list/shipment-order';
      } else if (url.includes('shipments-list') && url.includes('task')) {
        screen_name = '/shipments-list/shipment-order/task';
      } else if (url.includes('quotes')) {
        screen_name = '/quotes/shipment-order';
      } else if (url.includes('invoice')) {
        screen_name = '/invoices';
      }
      if (screen_name) {
        const props: any = {
          screen_name,
          button_name: 'Open message',
          parent_object: this.channelAndMessage.channelInfo.parentObject,
        };
        if (this.data.payload?.shipment?.master_task_id) {
          props.master_task_id = this.data.payload.shipment.master_task_id;
        }
        this.mixpanelService.track(MixpanelEvent.UserAction, props);
      }
    }
  }

  private registerEventHandlers(): void {
    this.sendbirdSerive.registerEventHandlers((data: { event: string; data: any }) => {
      if (
        this.channelAndMessage.channel?.url &&
        data.event === 'onMessageReceived' &&
        data.data.channel.url === this.channelAndMessage.channel.url
      ) {
        this.channelAndMessage.messages.push(data.data.message);
        this.cdr.markForCheck();
      }
    });
  }

  private startCaseDetailsPoll(): void {
    this._messageThreadDetails$
      .pipe(
        filter((messageThreadDetails) => !isNil(messageThreadDetails?.id)),
        distinctUntilKeyChanged('id'),
        switchMap((messageThreadDetails) => this.messageDialogDataService.pollMessageThread$(messageThreadDetails)),
        takeUntil(this.destroyed$)
      )
      .subscribe((messageThreadDetails) => {
        this.messageThreadDetails = messageThreadDetails;
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onMessageSend(messagePayload: MessagePayload): void {
    this.isSending = true;
  }
  public downloadS3Message(event: { objectKey: string }): void {
    this.sendbirdSerive.downloadS3Message(event);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onOutSideClick(value: any): void {
    this.data.dialogRef.close(value);
  }
}
