/* eslint-disable unicorn/no-nested-ternary */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { FormControl } from '@ngneat/reactive-forms';
import { Store } from '@ngrx/store';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { utc } from 'moment';
import { lastValueFrom, Observable, of, Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { AccountParticipantResponse } from '@tecex-api/data/model/accountParticipantResponse';
import { AllTecexPeopleResponseItem } from '@tecex-api/data/model/allTecexPeopleResponseItem';
import { ACTecexPeopleResponseItem, ClientContactsResponseItem, ClientUsersResponseItem, SOTecexPeopleResponseItem } from '@tecex-api/data';
import { AdminMessage, FileMessage, UserMessage } from '@sendbird/chat/message';
import { SendbirdError } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { CONFIG_TOKEN } from '../../../../../global/config/config.token';
import { GlobalConfig } from '../../../../../global/config/global-config.interface';
import { SourceApp } from '../../../../../global/enums/source-app.enum';
import { SendbirdMention } from '../../../../../global/interfaces/messages/sendbird-mention.interface';
import { User } from '../../../../../global/interfaces/user.interface';
import * as commonMessageActions from '../../../../../global/modules/message-thread/actions/common-messages.actions';
import { AuthService } from '../../../../../global/services/auth.service';
import { ErrorNotificationService } from '../../../../../global/services/error-notification.service';
import { MixpanelEvent } from '../../../../enums/mixpanel-event.enum';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { MessagePayload } from '../../../../interfaces/messages/message-payload.interface';
import { SendbirdMessagePayload } from '../../../../interfaces/messages/sendbird-message-payload.interface';
import { Participant } from '../../../../interfaces/participant.interface';
import { ShipmentOrderPerson } from '../../../../interfaces/shipment-order-person.interface';
import { TeamMemberLists } from '../../../../interfaces/team-member-lists.interface';
import { FileExtensionService } from '../../../../services/file-extension.service';
import { MixpanelService } from '../../../../services/mixpanel.service';
import { MessageDialogPayload } from '../../../message-dialog/types/message-dialog-payload.interface';
import * as commonMessageAction from '../../actions/common-messages.actions';
import * as classicCustomBuild from '../../ckClassicBuild/build/ckeditor';
import * as commonMessageReducer from '../../reducers';
import { MessagingAWSService } from '../../services/messaging-aws.service';
import { SendbirdChannelInfo, SendbirdChannelUsers, SendbirdService } from '../../services/sendbird-message.service';
import { SendbirdMessageParticipantListComponent } from '../sendbird-message-participant-list/sendbird-message-participant-list.component';
import { Adapter } from '../sendbird-message-thread/ckeditor.adapter';
import { SendBirdMessageThreadParticipantsPipe } from '../../../../pipes/sendbird-message-thread-participants.pipe';
import { MessageThreadParticipantsPipe } from '../../../../pipes/message-thread-participants.pipe';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { SendbirdMessageComponent } from '../sendbird-message/sendbird-message.component';
import { LoadingIndicatorComponent } from '../../../loading-indicator/components/loading-indicator/loading-indicator.component';
import { SendbirdSubjectComponent } from '../sendbird-subject/sendbird-subject.component';
import { SendbirdItemsToDisplay } from '../../../../interfaces/messages/sendbird-items-to-display';
import { SendbirdGuestUser } from '../../../../interfaces/messages/sendbird-guest-user';
import { TooltipDirective } from '../../../tooltip/tooltip.directive';
import * as fromMessagesReducer from '../../../../../../../projects/tecex/src/app/modules/messages/reducers';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';

@Component({
  selector: 'app-sendbird-message-thread',
  templateUrl: './sendbird-message-thread.component.html',
  styleUrls: ['./sendbird-message-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  standalone: true,
  imports: [
    SendbirdSubjectComponent,
    MatMenuModule,
    NgIf,
    SendbirdMessageParticipantListComponent,
    MatButtonModule,
    NgClass,
    MatProgressSpinnerModule,
    LoadingIndicatorComponent,
    SvgIconComponent,
    NgFor,
    SendbirdMessageComponent,
    MatProgressBarModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    ProfilePicturePipe,
    MessageThreadParticipantsPipe,
    SendBirdMessageThreadParticipantsPipe,
    TranslateModule,
    TooltipDirective,
  ],
})
export class SendbirdMessageThreadComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() public messageThread: any;
  @Input() public teamMembers: TeamMemberLists;
  @Input() public sendbirdMessage: Array<FileMessage | UserMessage | AdminMessage | SendbirdMessagePayload>;
  @Input() public sendingMessage: boolean;
  @Input() public sendbirdparticiapant: SendbirdChannelUsers[];
  @Input() public sendbirdchannel: any;
  @Input() public shipmentOrderPeople: ShipmentOrderPerson[];
  @Input() public payloadOfDialog: MessageDialogPayload;
  @Input() public defaultParticipantSectionFlag: boolean;
  @Input() public isguestUserEnabled: boolean;
  @Input() public selectedShipmentId: string;
  @Input() public selectedShipmentAccountId: string;
  @Input() public selectedTaskAccountId: string;
  @Input() public sendBirdChannelInfo: SendbirdChannelInfo;
  @Input() public parentObjectDynamicValue = '';
  @Input() public master_task_id = '';
  @Input() public taggedRoles;

  @Output() public sendsendbirdMessage = new EventEmitter<SendbirdMessagePayload>();
  @Output() public S3fileMessageDownload = new EventEmitter<{ objectKey: string }>();
  @Output() public outSideClick = new EventEmitter<any>();
  @Output() public send = new EventEmitter<MessagePayload>();
  @Output() public reload = new EventEmitter<any>();

  @ViewChild('scrollableMessages') public scrollContainer: ElementRef;
  @ViewChild('messageInput') public messageInput: ElementRef<HTMLDivElement>;
  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  @ViewChild(SendbirdMessageParticipantListComponent) sendbirdMessageParticipantListComponent: SendbirdMessageParticipantListComponent;

  public readonly RouteSegment = RouteSegment;
  public readonly messageControl = new FormControl<string>('');
  public readonly attachmentControl = new FormControl<FileList[]>();
  public readonly subjectControl = new FormControl<string>('', [Validators.required]);
  public readonly maxFileSizeInMb = 4;
  public showNewMessage = false;
  public scrollOffset;

  public attachments: File[];
  public filesExceededSizeLimit: File[];
  public isDraggingOver = false;
  public newBody = '';
  public userFeeds: SendbirdMention;
  public isCompleted = true;
  public isNewMessagePushed = false;
  public outOfOfficeUsers: { id: string; name: string; message: string; outOfOffice: boolean; standbyUser: string }[];
  /**
   * Only relevant when message thread does not exist yet but user added participants
   * The new participants will only be added when message thread is created
   */
  public newParticipants: Participant[] = [];

  private scrolled = false;
  private readonly destroyed$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ckEditor: any = classicCustomBuild;
  public color: ThemePalette = 'primary';
  public mode: MatProgressBarModule = 'determinate';
  public valueOfLoader: number;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public isUserJoined: boolean = true;
  public isStandbyUser = false;
  public ckeditorInstance = undefined;
  public listOfTaggedTeamMembers: any = [];
  public listOfGuestUsers: SendbirdGuestUser[] = [];
  public listOfUsersForAcc: any = [];
  public caseCreatedFlag = false;
  public user$: Observable<User> = this.authService.getUser$();
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public orgId: string = '';
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public caseId: string = '';

  public DetailOfMessageCreationCase$: Observable<any>;
  public nextButtonClickFlag = false;
  public tagParticipantsClickFlag = false;
  public channelDetailOfFirstMessage: boolean;
  public flagForSpinner = false;
  public editParticipantClickFlag = false;
  public deleteChannelAPICallFlag = false;
  public userDetail: User;
  public readonly isLoadingForAccountParticipants$ = this.store$.select(commonMessageReducer.selectAccountPeopleDataLoadingState);
  public readonly isLoadingForshipmentOrderParticipants$ = this.store$.select(
    commonMessageReducer.selectShipmentOrderPeopleDataLoadingState
  );

  public guestUserFormValue: boolean;
  public saveButtonEnableDisableFlagValue = false;
  public guestUserDisableViaFlag = false;
  public sendbirdChannelInfoDetail: any;
  public sendbirdExistingChannelUrl: string;
  public loadingStateForChannelInfoDetail$ = this.store$.select(commonMessageReducer.selectChannelInfoDetailLoadingState);
  public shipmentOrderParticipants$: Observable<any> = this.store$.select(commonMessageReducer.selectShipmentOrderPeople);
  public accountParticipants$ = this.store$.select(commonMessageReducer.selectListOfAccountParticipant);
  public flagForSubjectEnableDisable: boolean;
  public lengthOfMessageWhichSend = 0;
  public isThirdParty = this.authService.thirdPartyFlag();
  public totalFileCount = 0;
  public numberOfFileLoaded = 0;
  public initialLoaderValue = 0;
  public clearUsersOnMenuClose = true;
  public outOfOfficePeople = [];
  public allAccountPeople = [];
  public mentionedStandByUsers = [];

  // // Import the config for CKEditor for tools :

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly fileExtensionService: FileExtensionService,
    private readonly SendbirdServiceService: SendbirdService,
    private readonly authService: AuthService,
    public router: Router,
    private readonly store$: Store<commonMessageReducer.AppState & fromMessagesReducer.AppState>,
    private readonly messagingAWSService: MessagingAWSService,
    private readonly renderer: Renderer2,
    @Inject(CONFIG_TOKEN) private readonly configs: GlobalConfig,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly mixpanelService: MixpanelService,
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {
    this.user$.subscribe((userDetails) => {
      this.userDetail = userDetails;
    });

    this.store$.dispatch(commonMessageAction.channelInfoListEmpty());
  }

  public editorConfig = {
    extraPlugins: [Adapter],
    mention: {
      dropdownLimit: 10_000,
      feeds: [
        {
          marker: '@',
          feed: this.loadGetmention.bind(this),
          itemRenderer: (item: { userId: string; id: string; isMuted: boolean }): HTMLElement => {
            const itemElement = document.createElement('button');
            itemElement.classList.add('ck');
            itemElement.classList.add('ck-button');
            itemElement.classList.add('ck-off');
            itemElement.classList.add('ck-button_with-text');
            itemElement.setAttribute('type', 'button');
            itemElement.setAttribute('tabindex', '-1');
            itemElement.dataset.ckeTooltipPosition = 's';
            itemElement.id = `mention-list-item-id-${item.userId}`;

            const spanElement = document.createElement('span');
            spanElement.classList.add('ck');
            spanElement.classList.add('ck-button__label');
            spanElement.id = `mention-list-text-item-id-${item.userId}`;
            spanElement.textContent = `${item.id}`;

            if (item.isMuted) {
              const imgElement = document.createElement('img');
              imgElement.setAttribute('src', '/assets/images/mute.png');
              imgElement.setAttribute('style', 'height: 18px; padding-left: 5px;');

              spanElement.append(imgElement);
            }
            itemElement.append(spanElement);

            return itemElement;
          },
        },
      ],
    },
    mediaEmbed: { previewsInData: true },
  };

  public isScrollable(): boolean {
    return this.scrollContainer.nativeElement.scrollHeight > this.scrollContainer.nativeElement.clientHeight;
  }

  public showMsgBtnIfScrollable(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isScrollable() ? (this.showNewMessage = true) : (this.showNewMessage = false);
  }

  public hideShowMsgBtnOnScrollBottom(): void {
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const clientHeight = this.scrollContainer.nativeElement.clientHeight;

    this.scrollOffset = scrollHeight - scrollTop;

    // Check if the user is at the bottom of the div
    const isBottom = scrollTop + clientHeight >= scrollHeight;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isBottom ? (this.showNewMessage = false) : null;
  }

  public get allParticipants(): Participant[] {
    if (this.isNewThread()) {
      return this.newParticipants;
    }

    return this.messageThread.participants.concat(this.newParticipants);
  }

  public get hasAttachments(): boolean {
    return !isNil(this.attachments) && this.attachments.length > 0;
  }

  public get canSendMessage(): boolean {
    return (
      !this.sendingMessage &&
      (!isEmpty(this.messageControl.value) || this.hasAttachments) &&
      !isEmpty(this.subjectControl.value) &&
      this.isUserJoined
    );
  }

  public get isLastMessageSentByCurrentUser(): boolean {
    return false;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public ngOnChanges(changes: SimpleChanges): void {
    this.renderer.listen('window', 'click', (e: any) => {
      if (
        e.target.classList[0] === 'cdk-overlay-backdrop' &&
        !this.nextButtonClickFlag &&
        changes?.sendbirdchannel?.currentValue === null
      ) {
        this.outSideClick.emit(true);
      } else if (
        this.caseId &&
        this.orgId &&
        e.target.classList[0] === 'cdk-overlay-backdrop' &&
        e.target.classList[1] !== 'context-menu_backdrop' &&
        !this.deleteChannelAPICallFlag
      ) {
        this.deleteCreatedChannelFromAWS();
      } else if (
        this.caseId &&
        this.orgId &&
        e.target.classList.length === 0 &&
        (e.target.farthestViewportElement === null || e.target.farthestViewportElement?.parentElement?.className === 'svg-icon-close') &&
        e.target.parentElement.className !== 'delete-attachment-btn svg-icon-close' &&
        e.target.parentElement.className !== 'svg-icon-attachment' &&
        !e.target.classList.value.includes('ck-') &&
        this.canSendMessage === false &&
        e.srcElement.parentElement.className !== 'svg-icon-send2 ng-star-inserted' &&
        !this.deleteChannelAPICallFlag
      ) {
        this.deleteCreatedChannelFromAWS();
      } else if (this.listOfTaggedTeamMembers.length === 0 && changes?.sendbirdchannel?.currentValue === null) {
        this.outSideClick.emit(true);
      }
    });

    // For quote and shipment order message ( Existing channel with message ) :
    if (changes?.sendbirdchannel?.currentValue && changes.sendbirdchannel.currentValue.url) {
      this.sendbirdExistingChannelUrl = changes.sendbirdchannel.currentValue.url;
      this.guestUserDisableViaFlag = true;
      this.deleteChannelAPICallFlag = true;
      this.caseCreatedFlag = true;
      this.sendbirdChannelInfoDetail = changes?.sendbirdchannel?.currentValue;
    } else {
      this.guestUserDisableViaFlag = false;
      this.caseCreatedFlag = false;
    }

    if (changes.sendbirdparticiapant) {
      this.saveButtonEnableDisableFlagValue = false;
    }

    if (changes.sendingMessage) {
      this.messageControl.setDisable(this.sendingMessage);
      this.attachmentControl.setDisable(this.sendingMessage);
    }

    if (changes.sendbirdMessage) {
      this.handleMessageThreadChange(changes.sendbirdMessage);
      // setTimeout(() => this.scrollToBottom());
    }
  }

  // TODO Update ngOninit below

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public ngOnInit(): void {
    this.store$.dispatch(commonMessageAction.loadAccountParticipants());
    this.store$.select(commonMessageReducer.selectListOfAccountParticipant);
    this.accountParticipants$.subscribe((users) => {
      let allUsers = [];
      if (users.AllTecexPeople) {
        allUsers = users.AllTecexPeople.concat(users.ACTecexPeople, users.ThirdPartyPeople, users.ClientUsers);
        this.allAccountPeople = allUsers;
      }
      if (allUsers.length > 0) {
        this.outOfOfficePeople = allUsers.filter((user) => user.OutOfOffice);
      }
    });

    if (this.sendbirdchannel?.url) {
      this.SendbirdServiceService.getChannel(this.orgId && this.caseId ? `${this.orgId}-${this.caseId}` : this.sendbirdchannel?.url)
        .then((result: GroupChannel) => {
          const selectedChannel = result;
          if (this.SendbirdServiceService.isConnected()) {
            this.SendbirdServiceService.getMessagesFromChannel(
              selectedChannel,
              (errors: SendbirdError, messages: Array<AdminMessage | FileMessage | UserMessage>) => {
                if (!errors) {
                  this.sendbirdMessage = messages;
                  this.lengthOfMessageWhichSend = this.sendbirdMessage.length > 0 ? 1 : 0;
                  this.cdr.markForCheck();
                }
              }
            );
          }
        })
        .catch((error_) => {
          this.errorNotificationService.notifyAboutError(error_, 'ERROR.ERROR_GETTING_MENTION_LIST');
        });
    }

    if (this.sendbirdMessage || this.sendingMessage) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.sendbirdChannelInfoDetail.url ? (this.guestUserDisableViaFlag = this.isguestUserEnabled) : (this.guestUserDisableViaFlag = true);
    }

    this.store$.dispatch(commonMessageActions.loadAwstokens());

    if (
      (this.router.url.includes('dashboard') &&
        this.router.url.includes('task') &&
        this.payloadOfDialog.shipment &&
        this.payloadOfDialog.shipment?.id) ||
      ((this.router.url.includes('shipments-list') || this.router.url.includes('third-party')) && this.router.url.includes('task')) ||
      (this.router.url.includes('invoices') &&
        this.payloadOfDialog.id &&
        this.payloadOfDialog.invoiceName &&
        this.payloadOfDialog.shipment?.id) ||
      (this.payloadOfDialog?.source?.type === 'NCP Invoice' && this.payloadOfDialog.invoiceName && this.payloadOfDialog.shipment?.id)
    ) {
      // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
      this.store$.dispatch(
        commonMessageAction.loadShipmentOrderParticipants({
          SOID:
            this.payloadOfDialog && this.payloadOfDialog.shipment?.id
              ? this.payloadOfDialog.shipment?.id
              : this.sendbirdchannel?.shipment?.id,
          SOAccountId: this.isThirdParty ? this.selectedTaskAccountId : null,
        })
      );
    }
    // Adding this condition for Account Level tasks - Start
    else if (
      (this.router.url.includes('dashboard') &&
        this.router.url.includes('task') &&
        !this.payloadOfDialog.shipment &&
        !this.payloadOfDialog.shipment?.id) ||
      (this.router.url.includes('invoices') &&
        this.payloadOfDialog.id &&
        !this.payloadOfDialog.invoiceName &&
        !this.payloadOfDialog.shipment?.id) ||
      (this.payloadOfDialog?.source?.type === 'NCP Invoice' && this.payloadOfDialog.invoiceName && !this.payloadOfDialog.shipment?.id)
    ) {
      this.store$.dispatch(commonMessageAction.loadAccountParticipants());
    }
    // Adding this condition for Account Level tasks - End

    // tslint:disable-next-line: early-exit
    if (this.isNewThread() && this.messageThread?.participants?.length > 0) {
      // this.newParticipants = this.messageThread?.participants?.map((participant) => ({
      //   ...participant,
      //   isNew: true,
      // }));
    }
    this.SendbirdServiceService.getAwsTokens$();
    this.registerEventHandlers();
  }
  public loadGetmentionFeed$(): Observable<SendbirdMention> {
    let url = '';
    if (this.sendbirdchannel.Id) {
      url = `${this.userDetail.orgId}-${this.sendbirdchannel.Id}`;
    } else if (this.sendbirdchannel.url) {
      url = this.sendbirdchannel.url;
    } else if (this.sendbirdchannel.id) {
      url =
        this.sendbirdchannel.id.split('-').length === 1 ? `${this.userDetail.orgId}-${this.sendbirdchannel.id}` : this.sendbirdchannel.id;
    }

    return this.SendbirdServiceService.getMentionslist$(url);
  }

  public filterMention(queryText: string): Promise<SendbirdItemsToDisplay[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const isItemMatching = (item) => {
          // Make the search case-insensitive.
          const searchString = queryText.toLowerCase();

          return item.id.toLowerCase().includes(searchString) || item.userId.toLowerCase().includes(searchString);
        };

        const itemsToDisplay = this.userFeeds.mentionList
          .map((mentions) => {
            const outOfOfficeUser = this.outOfOfficePeople.find((outOfOfficeUser) => outOfOfficeUser.UserID === mentions.id);
            if (outOfOfficeUser) {
              // TODO Need to do some more elegant handling here.
              const standByUser = this.allAccountPeople.find(
                (user) => outOfOfficeUser.StandByUser && user.UserID === outOfOfficeUser.StandByUser
              );
              if (standByUser) {
                this.mentionedStandByUsers.push(standByUser);
                return {
                  userId: standByUser.UserID,
                  isMuted: true,
                  id: `@${mentions.nickname} (Standby: @${standByUser.FirstName} ${standByUser.LastName})`,
                };
              } else {
                return {
                  userId: mentions.id,
                  isMuted: mentions.isMuted,
                  id: `@${mentions.nickname}`,
                };
              }
            } else {
              return {
                userId: mentions.id,
                isMuted: mentions.isMuted,
                id: `@${mentions.nickname}`,
              };
            }
          })
          .filter((element) => isItemMatching(element));
        resolve(itemsToDisplay);
      }, 100);
    });
  }

  public async loadGetmention(queryText: string): Promise<SendbirdItemsToDisplay[]> {
    if (queryText.length === 0) {
      const mentionUsers: SendbirdMention = await lastValueFrom(this.loadGetmentionFeed$());
      this.userFeeds = mentionUsers;
      return this.filterMention(queryText);
    } else {
      return this.filterMention(queryText);
    }
  }

  private handleMessageThreadChange(change: SimpleChange): void {
    this.subjectControl.setValue(this.sendbirdchannel?.subject ? this.sendbirdchannel?.subject : this.sendbirdchannel?.name);
    if (this.sendbirdchannel) {
      this.isUserJoined = this.SendbirdServiceService.isuserJoined(this.sendbirdchannel, null);
      if (this.isUserJoined) {
        this.ckeditorInstance?.disableReadOnlyMode('cke');
        this.attachmentControl.enable();
        this.attachmentControl.setValue([]);
        this.messageControl.setValue('');

        this.subjectControl.enable();
      } else {
        this.ckeditorInstance?.enableReadOnlyMode('cke');
        this.attachmentControl.disable();
        this.subjectControl.disable();
        this.attachmentControl.setValue([]);
        this.messageControl.setValue('You can not send a message on this channel as you are not a participant.');
      }
    }
  }

  public ngAfterViewInit(): void {
    if (!this.scrolled) {
      this.scrollToBottom();
    }
    if (!this.sendbirdchannel) {
      this.menuTrigger.openMenu();
    }
  }

  public scrollToBottom(): void {
    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
  }

  public get subject(): string {
    return this.subjectControl.value;
  }

  public handleFileInputChange(event: Event): void {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;
    this.handleFiles(fileInput.files);
    this.cdr.markForCheck();
  }

  public handleFiles(fileList: FileList | null): void {
    if (!fileList && fileList.length > 0) {
      return;
    }

    const file = this.attachments ? [...this.attachments, ...fileList] : [...fileList];
    const uniqueFiles = file.filter((value, index, self) => index === self.findIndex((t) => t.name === value.name));
    this.attachments = uniqueFiles;
    this.attachments = this.fileExtensionService.handleDisallowedExtensions(this.attachments);
  }

  public onDismissAttachmentClick(attachment: File): void {
    if (this.sendingMessage) {
      return;
    }
    this.attachments = this.attachments.filter((item) => item !== attachment);
  }

  public onThreadScroll(): void {
    this.scrolled = true;
    this.hideShowMsgBtnOnScrollBottom();
  }

  public getMessagesFromChannelBySendBirdChannelURL(sendBirdURLFromExisting?: string): void {
    this.SendbirdServiceService.getChannel(this.orgId && this.caseId ? `${this.orgId}-${this.caseId}` : sendBirdURLFromExisting)
      .then((result: GroupChannel) => {
        const selectedChannel = result;
        if (this.SendbirdServiceService.isConnected()) {
          this.SendbirdServiceService.getMessagesFromChannel(
            selectedChannel,
            (errors: SendbirdError, messages: Array<AdminMessage | FileMessage | UserMessage>) => {
              if (!errors) {
                this.sendbirdMessage = messages;
                this.cdr.markForCheck();

                // When a message is received and loaded onto a page, it automatically scrolls :
                setTimeout(() => this.scrollToBottom());
              }
            }
          );
        }
      })
      .catch((error_) => {
        this.errorNotificationService.notifyAboutError(error_, 'ERROR.ERROR_GETTING_MENTION_LIST');
      });
  }

  public onTextAreaInput(): void {
    this.scrollToBottom();
  }
  public onBlur(): void {
    if (this.subjectControl.invalid) {
      this.subjectControl.markAllAsTouched();
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setLoaderValue(loaderValue: number): void {
    if (loaderValue === 100) {
      this.numberOfFileLoaded += 1;
      this.valueOfLoader = (this.numberOfFileLoaded * 100) / this.totalFileCount;
    } else {
      this.initialLoaderValue = this.numberOfFileLoaded > 0 ? (this.numberOfFileLoaded * 100) / this.totalFileCount : 0;
      this.valueOfLoader = loaderValue ? this.initialLoaderValue + loaderValue / this.totalFileCount : 0;
    }
    if (this.numberOfFileLoaded === this.totalFileCount) {
      this.initialLoaderValue = 0;
      this.numberOfFileLoaded = 0;
      this.totalFileCount = 0;
      this.isCompleted = true;
    }
    this.cdr.detectChanges();
  }

  public async addStandByUsers() {
    // Todo we need to check here if all mentioned users are in the channel
    // If they are not then we need to add them
    // const participantIds = this.sendbirdparticiapant.map((participant)=>{
    //   return participant.id
    // })

    const oooParticipantIds = new Set(this.outOfOfficePeople.map((participant) => participant.UserID));

    // const oooParticipants = this.sendbirdparticiapant.filter((participant)=> oooParticipantIds.includes(participant.id));
    const oooParticipants = this.sendbirdparticiapant.filter((participant) => oooParticipantIds.has(participant.id));
    // need to build object
    // getting all info we can from the main user
    const participantsToAdd: {
      id: string;
      email: string;
      nickname: string;
      isMuted: boolean;
      userType: string;
      userRole: string;
      affiliationCompany: string;
      profileUrlWebApp: string;
      affiliationType: string;
      userCompany: string;
    }[] = [];
    oooParticipants.forEach((oooParticipant) => {
      this.outOfOfficePeople.forEach((person) => {
        if (
          person.UserID == oooParticipant.id &&
          person.StandByUser &&
          this.sendbirdparticiapant.filter((participant) => participant.id === person.StandByUser).length === 0
        ) {
          participantsToAdd.push({
            affiliationCompany: oooParticipant.userCompany,
            userRole: oooParticipant.userRole,
            affiliationType: oooParticipant.affiliationType,
            id: person.StandByUser,
            email: '',
            nickname: '',
            isMuted: false,
            userType: oooParticipant.userType,
            profileUrlWebApp: oooParticipant.profileUrlWebApp,
            userCompany: oooParticipant.userCompany,
          });
        }
      });
    });
    // Have incomplete list now, need to get the email and nickname
    participantsToAdd.forEach((participant) => {
      const tempUser = this.allAccountPeople.find((person) => person.UserID === participant.id);
      participant.email = tempUser.Useremail;
      participant.nickname = `${tempUser.FirstName} ${tempUser.LastName}`;
    });

    // Now we have all the users that we need. We can concat it to the list of existing users
    const newParticipantList = [...this.sendbirdparticiapant, ...participantsToAdd];
    // Now we can update the channel
    // We need to get the up to date mute statuses
    await this.messagingAWSService
      .updateChannelUserList({ sendbirdChannelUrl: this.sendbirdchannel?.url, users: newParticipantList }, `${this.userDetail.orgId}`)
      .subscribe((data) => {
        // eslint-disable-next-line no-console
        return console.info('Done');
      });
    this.sendbirdparticiapant = newParticipantList;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity, @typescript-eslint/explicit-module-boundary-types
  public async onSendMessageClick(userDetail?: User) {
    if (this.sendbirdparticiapant) {
      await this.addStandByUsers();
    }
    if (!this.subjectControl.value) {
      this.subjectControl.setValue(this.sendbirdchannel?.name);
    }
    if (this.subjectControl.invalid) {
      this.subjectControl.markAllAsTouched();
      return;
    }

    const body = this.newBody;
    let channelDetailFromSendbird: any;
    this.messageControl.setValue('');
    this.attachmentControl.setValue([]);
    const s3Attachment = this.attachments;
    this.totalFileCount = this.attachments?.length ? this.attachments?.length : 0;
    this.isCompleted = s3Attachment && s3Attachment.length > 0 ? false : true;
    this.valueOfLoader = 0;
    this.initialLoaderValue = 0;
    this.numberOfFileLoaded = 0;
    this.attachments = [];

    const mention = [];
    if (body || s3Attachment) {
      if (
        this.router.url.includes('message') &&
        this.payloadOfDialog?.nextButtonClickFromMessageFlag !== undefined &&
        !this.payloadOfDialog.nextButtonClickFromMessageFlag
      ) {
        this.sendsendbirdMessage.emit({
          subject: this.subjectControl.value,
          body,
          mention,
          attachments: s3Attachment,
          channelUrl:
            this.caseId && this.orgId
              ? `${this.orgId}-${this.caseId}`
              : this.sendbirdchannel.url
              ? this.sendbirdchannel.url
              : this.sendbirdchannel.id,
          callbackFunc: this.setLoaderValue.bind(this),
        });
        this.deleteChannelAPICallFlag = true;
        this.guestUserDisableViaFlag = true;
        this.flagForSubjectEnableDisable = false;
        this.lengthOfMessageWhichSend = 1;
      } else {
        const message: SendbirdMessagePayload = {
          subject: this.subjectControl.value,
          body,
          mention,
          attachments: s3Attachment,
          channelUrl:
            this.caseId && this.orgId
              ? `${this.orgId}-${this.caseId}`
              : this.sendbirdchannel.url
              ? this.sendbirdchannel.url
              : this.sendbirdchannel.id,
          callbackFunc: this.setLoaderValue.bind(this),
        };

        this.SendbirdServiceService.getChannel(
          this.caseId && this.orgId
            ? `${this.orgId}-${this.caseId}`
            : this.sendbirdchannel.url
            ? this.sendbirdchannel.url
            : this.sendbirdchannel.id
        )
          .then((channel: GroupChannel) => {
            // eslint-disable-next-line prefer-const
            this.channelDetailOfFirstMessage = channel.lastMessage === null ? true : false;
            this.deleteChannelAPICallFlag = true;
            this.flagForSubjectEnableDisable = false;
            this.sendbirdchannel = channel;

            this.SendbirdServiceService.sendMessage(
              channel,
              message,
              // eslint-disable-next-line @typescript-eslint/no-shadow
              (error: SendbirdError, userMessage: UserMessage | FileMessage, mutedUsers?) => {
                // Below is to remove the muted status from our local copy of sendbird participants
                // This needs to be done this way, as with the OOO we can now add users without actually opening the ACC
                // Which results in the unmuting getting overwritten
                if (mutedUsers && this.sendbirdparticiapant) {
                  const unMutedUsers = [];
                  this.sendbirdparticiapant.forEach((participant) => {
                    if (mutedUsers.includes(participant.id)) {
                      const copy = participant;
                      unMutedUsers.push({
                        affiliationCompany: participant.userCompany,
                        userRole: participant.userRole,
                        affiliationType: participant.affiliationType,
                        id: participant.id,
                        email: participant.email,
                        nickname: participant.nickname,
                        isMuted: false,
                        userType: participant.userType,
                        profileUrlWebApp: participant.profileUrlWebApp,
                        userCompany: participant.userCompany,
                      });
                    }
                  });
                  //Need to pop out the wrong user
                  // Create new user and push it in
                  this.sendbirdparticiapant = this.sendbirdparticiapant.filter((participant) => !mutedUsers.includes(participant.id));
                  this.sendbirdparticiapant = [...this.sendbirdparticiapant, ...unMutedUsers];
                }
                if (!error && this.channelDetailOfFirstMessage) {
                  this.deleteChannelAPICallFlag = true;
                  channelDetailFromSendbird = channel;
                  this.lengthOfMessageWhichSend = channel?.lastMessage ? 1 : 0;
                  this.sendbirdMessage = [];
                  this.sendbirdMessage.push(message);

                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  this.caseId && this.subjectControl.value
                    ? this.store$.dispatch(
                        commonMessageAction.updateMessageCases({
                          CaseID: this.caseId,
                          Subject: this.subjectControl.value,
                          Description: channelDetailFromSendbird.lastMessage.message
                            ? channelDetailFromSendbird.lastMessage.message
                            : `File uploaded by ${userDetail.name} to channel: ${channelDetailFromSendbird.lastMessage.name}`,
                          orgId: this.orgId,
                        })
                      )
                    : null;
                } else if (error && error.code === 800_200) {
                  this.isCompleted = true;
                }
                this.getMessagesFromChannelBySendBirdChannelURL(
                  this.caseId && this.orgId
                    ? `${this.orgId}-${this.caseId}`
                    : this.sendbirdchannel.url
                    ? this.sendbirdchannel.url
                    : this.sendbirdchannel.id
                );
              }
            );
          })
          .catch((error_) => {
            this.errorNotificationService.notifyAboutError(error_, 'ERROR.ERROR_SENDING_MESSAGE');
          });

        // });
      }
      const url = this.router.url;
      let screen_name = '';
      if (url.includes('dashboard') && url.includes('task')) {
        screen_name = '/dashboard/task/message-modal';
      } else if (url.includes('quotes')) {
        screen_name = '/quotes/shipment-order/message-modal';
      } else if (url.includes('shipments-list') && !url.includes('task')) {
        screen_name = '/shipments-list/shipment-order/message-modal';
      } else if (url.includes('shipments-list') && url.includes('task')) {
        screen_name = '/shipments-list/shipment-order/task/message-modal';
      } else {
        screen_name = `${url}/message-modal`;
      }
      const props: any = {
        screen_name,
        button_name: 'Send',
        has_attachment: !!s3Attachment,
        attachment_count: s3Attachment ? s3Attachment.length : 0,
        parent_object: this.parentObjectDynamicValue,
      };
      if (this.master_task_id) {
        props.master_task_id = this.master_task_id;
      }
      this.mixpanelService.track(MixpanelEvent.UserAction, props);
    }
  }

  public onS3fileMessageDownloadClick(event: { objectKey: string }): void {
    this.S3fileMessageDownload.emit(event);
  }
  public onParticipantAdd(participant: Participant): void {
    this.newParticipants = this.newParticipants.concat({
      ...participant,
      isNew: true,
    });
  }

  public onParticipantRemove(participant: Participant): void {
    this.newParticipants = this.newParticipants.filter(({ id }) => id !== participant.id);
  }

  public clickAddMyself(): void {
    this.loadingIndicatorService.open();
    // Start new array
    this.listOfTaggedTeamMembers = [];
    // Form user
    const user = {
      firstName: this.userDetail.name.slice(0, this.userDetail.name.indexOf(' ')),
      id: this.userDetail.id,
      lastName: this.userDetail.name.slice(this.userDetail.name.indexOf(' ') + 1),
      photoUrl: this.userDetail.profilePicture,
      roleOfParticipant: '',
      DefaultMuteStatus: false,
      Useremail: this.userDetail.email,
      nickName: this.userDetail.name,
      userId: this.userDetail.id,
      contactId: this.userDetail.contactId,
    };
    // Add all participants to array
    this.sendbirdparticiapant.forEach((d) => {
      const userCopy = { ...d };
      userCopy.OutOfOffice = false;
      this.listOfTaggedTeamMembers.push(userCopy);
    });
    // Add user to array
    this.listOfTaggedTeamMembers.push(user);
    this.updateChannel(this.userDetail, this.sendbirdchannel?.url?.split('-')[1] || this.sendbirdchannel?.id).then((r) => {
      setTimeout(() => {
        this.loadingIndicatorService.dispose();
        this.reload.emit();
      }, 2000);
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.SendbirdServiceService.unRegisterEventHandlers();
  }

  private isNewThread(): boolean {
    return this.sendbirdMessage?.length > 0 ? false : true;
  }

  public removeInvalidFileClick(fileName: string): void {
    this.filesExceededSizeLimit = this.filesExceededSizeLimit.filter((file) => file.name !== fileName);
  }

  public onDragStart(event: DragEvent): void {
    if (event.dataTransfer.types[0] === 'text/html' || event.dataTransfer.types[1] === 'text/plain') {
      this.isDraggingOver = false;
    }
  }

  public onDragEnter(event: DragEvent): void {
    this.isDraggingOver = event.dataTransfer.types[0] === 'Files' ? true : false;
  }

  public onDragLeave(): void {
    this.isDraggingOver = false;
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggingOver = false;
    if (isNil(event.dataTransfer)) {
      return;
    }

    if (event.dataTransfer.files.length > 0) {
      this.handleFiles(event.dataTransfer.files);
    }
  }

  public dragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  onChange(editor: ChangeEvent): void {
    const textdata = editor?.editor?.getData();
    this.newBody = textdata;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-member-accessibility
  onReady(editor: any) {
    if (editor) {
      this.ckeditorInstance = editor;

      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new Adapter(loader);
      };

      const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

      imageUploadEditing?.on('uploadComplete', (evt, { data, imageElement }) => {
        editor.model.change((writer) => {
          writer.setAttribute('alt', data.filename, imageElement);
          writer.setAttribute('src', data.urls.default, imageElement);
          evt.stop();
        });
      });
      editor.conversion.for('downcast').add((dispatcher: any) =>
        dispatcher.on('attribute:uploadStatus:imageBlock', (evt: any, data: any, conversionApi: any) => {
          const viewWriter = conversionApi.writer;
          const figure = conversionApi.mapper.toViewElement(data.item);
          const img = figure.getChild(0);

          if (data.attributeNewValue !== 'complete') {
            const src = data.item.getAttribute('src');
            const fileName = data.item.getAttribute('alt');
            viewWriter.setAttribute('src', src, img);
            viewWriter.setAttribute('alt', fileName, img);
            evt.stop();
          }
        })
      );

      // CKEditorInspector.attach('header-editor', editor);
      if (this.sendbirdchannel) {
        this.isUserJoined = this.SendbirdServiceService.isuserJoined(this.sendbirdchannel);
        if (this.isUserJoined) {
          this.ckeditorInstance.disableReadOnlyMode('cke');
          this.attachmentControl.enable();
          this.attachmentControl.setValue([]);
          this.messageControl.setValue('');
          this.subjectControl.enable();

          this.ckeditorInstance.ui.view.toolbar.items.get(18).isEnabled = true;
        } else {
          this.ckeditorInstance.enableReadOnlyMode('cke');
          this.ckeditorInstance.ui.view.toolbar.items.get(18).isEnabled = false;
          this.attachmentControl.disable();
          this.subjectControl.disable();
          this.attachmentControl.setValue([]);
          this.subjectControl.setValue('');
          this.messageControl.setValue('You can not send a message on this channel as you are not a participant.');
        }
      }
    }
  }

  public cancelButtonClick(): void {
    if (!this.caseId && !this.orgId) this.outSideClick.emit(true);

    // Closing the menu of ACC from cancel button click :
    this.menuTrigger.closeMenu();
  }

  /*
Below function creates and dispatches action to create message case on SF side.
 */

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public async createMessageChannelCaseClick(event: MouseEvent, userDetail: User): Promise<void> {
    this.guestUserDisableViaFlag = true;
    this.flagForSpinner = true;
    event.stopPropagation();
    this.nextButtonClickFlag = true;
    this.deleteChannelAPICallFlag = false;
    this.saveButtonEnableDisableFlagValue = false;

    await this.detailOfMessageCreationCases(true);

    let count = 0;
    this.DetailOfMessageCreationCase$.subscribe((DetailOfMessageCreationCase) => {
      if (DetailOfMessageCreationCase.Id && count === 1) {
        this.createChannel(DetailOfMessageCreationCase, userDetail.orgId, userDetail);
      }
      count++;
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public detailOfMessageCreationCases(createMessageCaseFlag: boolean): void {
    // For Dashboard / Invoices Tab in General / Messages Tab (left side) / New Quote Creation :
    if (
      (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
      (this.router.url.includes('message') && !this.router.url.includes('task')) ||
      (this.router.url.includes('invoices') &&
        !this.payloadOfDialog.id &&
        !this.payloadOfDialog.shipment &&
        !this.payloadOfDialog.shipment?.id) ||
      this.router.url.includes('basics') ||
      this.router.url.includes('product-catalog') ||
      this.router.url.includes('quote-guidance')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({})) : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Quote in Quotes Tab / Shipment Order tab / Tracking Tab / In new quote - line-items :
    else if (
      this.router.url.includes('quotes') ||
      (this.router.url.includes('shipments-list') &&
        this.payloadOfDialog.shipment &&
        (!this.payloadOfDialog.shipment?.id || !this.payloadOfDialog.id)) ||
      this.router.url.includes('tracking') ||
      this.router.url.includes('line-items') ||
      this.router.url.includes('final-costs') ||
      this.router.url.includes('shipment-method') ||
      this.router.url.includes('third-party') ||
      this.router.url.includes('new-quote/rollout')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id }))
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Invoice on the Invoices Tab :
    else if (this.router.url.includes('invoices') && this.payloadOfDialog.id && this.payloadOfDialog.shipment.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({
              SOID: this.payloadOfDialog.shipment.id,
              InvoiceID: this.payloadOfDialog.id,
            })
          )
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Shipment Order in the Shipment Orders tab / Task from the Dashboard Tab :
    else if (
      (this.router.url.includes('shipments-list') || this.router.url.includes('third-party')) &&
      this.payloadOfDialog.shipment.id &&
      this.payloadOfDialog.id
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id, TaskID: this.payloadOfDialog.id })
          )
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Task from the Dashboard Tab :
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    else if (
      this.router.url.includes('dashboard') &&
      this.payloadOfDialog.shipment &&
      this.payloadOfDialog.shipment.id &&
      this.payloadOfDialog.id
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id, TaskID: this.payloadOfDialog.id })
          )
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Task from the Dashboard Tab :
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    else if (
      // eslint-disable-next-line sonarjs/no-identical-conditions
      this.router.url.includes('dashboard') &&
      this.router.url.includes('task') &&
      this.payloadOfDialog.id &&
      !this.payloadOfDialog.shipment &&
      !this.payloadOfDialog.shipment?.id
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({ TaskID: this.payloadOfDialog.id })) : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
  }

  /*
Below function creates message channel on AWS side
*/

  // eslint-disable-next-line sonarjs/cognitive-complexity, @typescript-eslint/explicit-module-boundary-types
  public createChannel(responseOfCreatedChannel: any, orgId: string, userDetail: User): void {
    this.deleteChannelAPICallFlag = false;
    let parentNameDynamicValue: string;
    let parentIdDynamicValue: string;
    let shipmentOrderIdDynamicValue: string;
    let directRefUrlDynamicValue: string;

    if (
      ((this.router.url.includes('shipments-list') || this.router.url.includes('third-party')) &&
        !this.router.url.includes('task') &&
        !this.payloadOfDialog.invoiceName) ||
      (this.router.url.includes('quote') && !this.router.url.includes('-quote') && !this.router.url.includes('guidance')) ||
      this.router.url.includes('tracking') ||
      this.router.url.includes('line-items') ||
      this.router.url.includes('final-costs') ||
      this.router.url.includes('shipment-method') ||
      this.router.url.includes('new-quote/rollout')
    ) {
      this.parentObjectDynamicValue = 'Shipment Order';
      directRefUrlDynamicValue = `${window.location.href.split(this.router.url)[0]}/shipments-list/${this.payloadOfDialog.shipment.id}`;

      parentIdDynamicValue = this.payloadOfDialog.shipment.id;
      shipmentOrderIdDynamicValue = this.payloadOfDialog.shipment.id;
      parentNameDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.title && this.payloadOfDialog.shipment.reference
          ? `${this.payloadOfDialog.shipment.title} - ${this.payloadOfDialog.shipment.reference}`
          : this.payloadOfDialog.shipment.title;
    } else if (
      (this.router.url.includes('invoices') &&
        (this.payloadOfDialog.id || this.payloadOfDialog.invoiceName) &&
        this.payloadOfDialog.shipment.id) ||
      (this.router.url.includes('shipments-list') &&
        this.payloadOfDialog.invoiceName &&
        this.payloadOfDialog.shipment &&
        this.payloadOfDialog.shipment.id)
    ) {
      this.parentObjectDynamicValue = 'Invoice';
      directRefUrlDynamicValue = `${window.location.href.split(this.router.url)[0]}/messages/${responseOfCreatedChannel.Id}`;
      shipmentOrderIdDynamicValue = this.payloadOfDialog.shipment.id;
      parentIdDynamicValue = this.payloadOfDialog.id;
      parentNameDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.title && this.payloadOfDialog.shipment.reference
          ? // eslint-disable-next-line max-len
            `${this.payloadOfDialog.shipment.title} - ${this.payloadOfDialog.shipment.reference} - Invoice ${this.payloadOfDialog.invoiceName}`
          : `${this.payloadOfDialog.shipment.title} - Invoice ${this.payloadOfDialog.invoiceName}`;
    } else if (
      ((this.router.url.includes('shipments-list') || this.router.url.includes('third-party')) &&
        this.router.url.includes('task') &&
        this.payloadOfDialog.shipment.id &&
        !this.payloadOfDialog.invoiceName) ||
      (this.router.url.includes('dashboard') && this.router.url.includes('task'))
    ) {
      this.parentObjectDynamicValue = 'Task';
      const url =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.id
          ? `${window.location.href.split(this.router.url)[0]}/shipments-list/${this.payloadOfDialog.shipment.id}#${
              window.location.href.split('#')[1]
            }`
          : `${window.location.href.split(this.router.url)[0]}/messages/${responseOfCreatedChannel.Id}`;
      directRefUrlDynamicValue = this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.id ? url.split('/message')[0] : url;
      shipmentOrderIdDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.id ? this.payloadOfDialog.shipment.id : null;
      parentIdDynamicValue = this.payloadOfDialog.id;
      parentNameDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.parentName
          ? this.payloadOfDialog.shipment.parentName
          : this.payloadOfDialog.taskNameForAccountLevel;
    } else if (
      this.router.url.includes('message') ||
      (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
      this.router.url.includes('invoices') ||
      this.router.url.includes('basics') ||
      this.router.url.includes('product-catalog') ||
      this.router.url.includes('quote-guidance')
    ) {
      this.parentObjectDynamicValue = 'Account';
      parentNameDynamicValue = userDetail.accountName;
      parentIdDynamicValue = userDetail.accountId;
      shipmentOrderIdDynamicValue = null;
      directRefUrlDynamicValue = `${window.location.href.split(this.router.url)[0]}/messages/${responseOfCreatedChannel.Id}`;
    }

    let users = [];

    for (const elementOfTaggedMember of this.listOfTaggedTeamMembers) {
      let userTypeDynamicValue = '';
      let userRoleDynamicValue = '';
      let userCompanyDynamicValue = '';
      let affiliationTypeDynamicValue = '';

      // For ACTecexPeople, SOTecexPeople and AllTecexPeople :
      if (
        this.listOfUsersForAcc.AllTecexPeople?.find((x: AllTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.ACTecexPeople?.find((x: ACTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.SOTecexPeople?.find((x: SOTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id)
      ) {
        userTypeDynamicValue = 'Internal';
        userRoleDynamicValue =
          this.listOfUsersForAcc.ACTecexPeople?.find((x: ACTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) ||
          (this.listOfUsersForAcc.SOTecexPeople?.find((x: SOTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) &&
            elementOfTaggedMember.Role)
            ? elementOfTaggedMember.Role
            : null;
        userCompanyDynamicValue = SourceApp.TecEx;
      }
      // For client user and client contacts :
      else if (
        this.listOfUsersForAcc.ClientUsers?.find((x: ClientUsersResponseItem) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.ClientContacts?.find((x: ClientContactsResponseItem) => x.contactID === elementOfTaggedMember.id)
      ) {
        userTypeDynamicValue = 'Client';
        userRoleDynamicValue = null;
        userCompanyDynamicValue = this.listOfUsersForAcc.ClientUsers?.find(
          (x: ClientUsersResponseItem) => x.UserID === elementOfTaggedMember.id
        )?.CompanyName
          ? this.listOfUsersForAcc.ClientUsers?.find((x: ClientUsersResponseItem) => x.UserID === elementOfTaggedMember.id).CompanyName
          : this.listOfUsersForAcc.ClientContacts?.find((x: ClientContactsResponseItem) => x.contactID === elementOfTaggedMember.id)
              .CompanyName;
      }
      // For third-party participants :
      else if (this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.contactID === elementOfTaggedMember.id)) {
        userTypeDynamicValue = 'Affiliate';
        userRoleDynamicValue = null;
        userCompanyDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
          .ThirdPartyCompany;
        affiliationTypeDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
          .AffiliateType;
      }

      const objForUserList = {
        id:
          userTypeDynamicValue === 'Client'
            ? // eslint-disable-next-line unicorn/no-nested-ternary
              elementOfTaggedMember.userId
              ? elementOfTaggedMember.userId
              : elementOfTaggedMember.id
            : elementOfTaggedMember.id,
        email: elementOfTaggedMember.Useremail
          ? elementOfTaggedMember.Useremail
          : // eslint-disable-next-line unicorn/no-nested-ternary
          elementOfTaggedMember.userEmail
          ? elementOfTaggedMember.userEmail
          : elementOfTaggedMember.Email,
        nickname: elementOfTaggedMember.nickName
          ? elementOfTaggedMember.nickName
          : // eslint-disable-next-line unicorn/no-nested-ternary
          elementOfTaggedMember.FirstName && elementOfTaggedMember.LastName
          ? `${elementOfTaggedMember.FirstName} ${elementOfTaggedMember.LastName}`
          : elementOfTaggedMember.firstName,
        isMuted: elementOfTaggedMember.DefaultMuteStatus ? true : false,
        userType: userTypeDynamicValue ? userTypeDynamicValue : null,
        userRole: userRoleDynamicValue ? userRoleDynamicValue : null,
        userCompany: userCompanyDynamicValue ? userCompanyDynamicValue : null,
        affiliationType: affiliationTypeDynamicValue ? affiliationTypeDynamicValue : null,
        profileUrlWebApp: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
      };

      users.push(objForUserList);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (this.listOfGuestUsers.length > 0) users = users.concat(this.listOfGuestUsers);

    this.caseCreatedFlag = responseOfCreatedChannel.Id ? true : false;
    this.sendbirdchannel = responseOfCreatedChannel;

    const channelCreateWithUserDetail: any = {
      orgId: `${orgId}`,
      orgMyName: this.getDynamicOrgMyName(),
      caseId: responseOfCreatedChannel.Id,
      accountId: userDetail.accountId,
      sendbirdChannelUrl: `${orgId}-${responseOfCreatedChannel.Id}`,
      emailTicketId: `${'TX'}${responseOfCreatedChannel.CaseNumber}`,
      channelName: '-= DRAFT =-',
      clientName: userDetail.accountName,
      lastActivityDate: utc(),
      lastModifiedBy: userDetail.id,
      lastEmailSent: null,
      accountTzLocation: responseOfCreatedChannel.Account.Preferred_Time_Zone__c,
      channelStatus: 'open',
      reOpenEnabled: false,
      businessUnit: SourceApp.TecEx,

      // Dynamically manage via page wise :
      parentObject: this.parentObjectDynamicValue,
      parentName: parentNameDynamicValue,
      parentId: parentIdDynamicValue,
      shipmentOrderId: shipmentOrderIdDynamicValue,
      directRefUrl: directRefUrlDynamicValue,
      guestUserEnabled:
        // eslint-disable-next-line unicorn/no-nested-ternary
        typeof this.guestUserFormValue === 'boolean'
          ? this.guestUserFormValue
          : this.router.url.includes('invoices') ||
            (this.router.url.includes('shipments-list') &&
              this.payloadOfDialog.invoiceName &&
              this.payloadOfDialog.shipment &&
              this.payloadOfDialog.shipment.id)
          ? false
          : true,

      // add user dynamically :
      users,
    };

    /* Remove the code because of the passing caseID not,
     task or shipment order id : this.caseId = this.messageThread.id ? this.messageThread.id : responseOfCreatedChannel.Id; */

    this.caseId = responseOfCreatedChannel.Id;
    this.orgId = userDetail.orgId;

    this.messagingAWSService.channelCreate(channelCreateWithUserDetail, orgId).subscribe((data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.router.url.includes('task')
        ? this.subjectControl.setValue(
            this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.parentName
              ? this.payloadOfDialog.shipment.parentName
              : this.payloadOfDialog.taskNameForAccountLevel
          )
        : '';
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.router.url.includes('task') ? this.subjectControl.disable() : '';
      this.flagForSpinner = false;
      this.ckeditorInstance.disableReadOnlyMode('cke');
      this.isUserJoined = true;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.router.url.includes('task') ? '' : this.subjectControl.enable();

      if (
        (this.router.url.includes('quotes') || this.router.url.includes('new-quote')) &&
        this.payloadOfDialog.subject === 'Request Quote Approval'
      ) {
        this.subjectControl.setValue(this.payloadOfDialog.subject);
      }
      const url = this.router.url;
      if (url.includes('dashboard#task') || url.includes('shipments-list' && 'task') || url.includes('invoices')) {
        let screen_name: string;
        if (url === 'dashboard') {
          screen_name = 'dashboard';
        } else if (url.includes('dashboard#task')) {
          screen_name = '/dashboard/task';
        } else if (url.includes('shipments-list')) {
          screen_name = '/shipments-list/shipment-order/task';
        } else if (url.includes('invoices')) {
          screen_name = '/invoices';
        }
        if (screen_name) {
          const props: any = {
            screen_name,
            button_name: 'New message',
          };
          if (this.master_task_id) {
            props.master_task_id = this.master_task_id;
          }
          if (url.includes('invoices')) {
            props.parent_object = this.parentObjectDynamicValue;
          }
          this.mixpanelService.track(MixpanelEvent.UserAction, props);
        }
      }
      this.menuTrigger.closeMenu();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public async updateChannel(userDetail: User, sendBirdChannelObject: string): Promise<void> {
    this.saveButtonEnableDisableFlagValue = false;
    await this.detailOfMessageCreationCases(false);

    let count = 0;
    let responseOfCreatedChannel: any;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.DetailOfMessageCreationCase$
      ? this.DetailOfMessageCreationCase$.subscribe((DetailOfMessageCreationCase) => {
          if (DetailOfMessageCreationCase.Id && count === 0) {
            responseOfCreatedChannel = DetailOfMessageCreationCase;
            count++;
          }
        })
      : null;

    if (responseOfCreatedChannel && responseOfCreatedChannel?.Id ? responseOfCreatedChannel?.Id : sendBirdChannelObject) {
      let users = [];

      for (const elementOfTaggedMember of this.listOfTaggedTeamMembers) {
        let userTypeDynamicValue = '';
        let userRoleDynamicValue = '';
        let userCompanyDynamicValue = '';
        let affiliationTypeDynamicValue = '';

        // For ACTecexPeople, SOTecexPeople and AllTecexPeople :
        if (
          this.listOfUsersForAcc.AllTecexPeople?.find((x: AllTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.ACTecexPeople?.find((x: ACTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.SOTecexPeople?.find((x: SOTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id)
        ) {
          userTypeDynamicValue = 'Internal';
          userRoleDynamicValue =
            this.listOfUsersForAcc.ACTecexPeople?.find((x: ACTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) ||
            (this.listOfUsersForAcc.SOTecexPeople?.find((x: SOTecexPeopleResponseItem) => x.UserID === elementOfTaggedMember.id) &&
              (elementOfTaggedMember.Role || elementOfTaggedMember.userRole))
              ? elementOfTaggedMember.Role || elementOfTaggedMember.userRole
              : null;
          userCompanyDynamicValue = SourceApp.TecEx;
        }
        // For client user and client contacts :
        else if (
          this.listOfUsersForAcc.ClientUsers?.find((x: ClientUsersResponseItem) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.ClientContacts?.find((x: ClientContactsResponseItem) => x.contactID === elementOfTaggedMember.id)
        ) {
          userTypeDynamicValue = 'Client';
          userRoleDynamicValue = null;
          userCompanyDynamicValue = this.listOfUsersForAcc.ClientUsers?.find(
            (x: ClientUsersResponseItem) => x.UserID === elementOfTaggedMember.id
          )?.CompanyName
            ? this.listOfUsersForAcc.ClientUsers?.find((x: ClientUsersResponseItem) => x.UserID === elementOfTaggedMember.id).CompanyName
            : this.listOfUsersForAcc.ClientContacts?.find((x: ClientContactsResponseItem) => x.contactID === elementOfTaggedMember.id)
                .CompanyName;
        }
        // For third-party participants :
        else if (this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)) {
          userTypeDynamicValue = 'Affiliate';
          userRoleDynamicValue = null;
          userCompanyDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
            .ThirdPartyCompany;
          affiliationTypeDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
            .AffiliateType;
        }

        let nickName;

        if (elementOfTaggedMember.FirstName && elementOfTaggedMember.LastName) {
          nickName = `${elementOfTaggedMember.FirstName} ${elementOfTaggedMember.LastName}`;
        } else if (elementOfTaggedMember.Name) {
          nickName = elementOfTaggedMember.Name;
        } else if (elementOfTaggedMember.firstName && elementOfTaggedMember.lastName) {
          nickName = `${elementOfTaggedMember.firstName} ${elementOfTaggedMember.lastName}`;
        } else if (elementOfTaggedMember.nickname) {
          nickName = elementOfTaggedMember.nickname;
        } else if (elementOfTaggedMember.AffiliateUserName) {
          nickName = elementOfTaggedMember.AffiliateUserName;
        }

        let email;

        if (elementOfTaggedMember.Useremail) {
          email = elementOfTaggedMember.Useremail;
        } else if (elementOfTaggedMember.userEmail) {
          email = elementOfTaggedMember.userEmail;
        } else if (elementOfTaggedMember.Email) {
          email = elementOfTaggedMember.Email;
        } else if (elementOfTaggedMember.email) {
          email = elementOfTaggedMember.email;
        } else if (elementOfTaggedMember.ThirdPartyEmail) {
          email = elementOfTaggedMember.ThirdPartyEmail;
        }

        let defaultMuteStatus;

        if (elementOfTaggedMember.DefaultMuteStatus) {
          defaultMuteStatus = true;
        } else if (elementOfTaggedMember.isMuted) {
          defaultMuteStatus = elementOfTaggedMember.isMuted;
        } else if (!elementOfTaggedMember.DefaultMuteStatus || !elementOfTaggedMember.isMuted) {
          defaultMuteStatus = false;
        }

        const objForUserList = {
          id: elementOfTaggedMember.id,
          email,
          nickname: nickName,
          isMuted: defaultMuteStatus,
          userType: userTypeDynamicValue,
          userRole: userRoleDynamicValue,
          userCompany: userCompanyDynamicValue,
          affiliationType: affiliationTypeDynamicValue,
          profileUrlWebApp: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
        };

        users.push(objForUserList);
      }

      this.caseId = responseOfCreatedChannel && responseOfCreatedChannel.Id ? responseOfCreatedChannel.Id : sendBirdChannelObject;
      this.orgId = userDetail.orgId;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (this.listOfGuestUsers.length > 0) users = users.concat(this.listOfGuestUsers);

      const userListOfDetail: any = {
        // orgId-caseId = orgId and existing case id :
        sendbirdChannelUrl:
          sendBirdChannelObject || sendBirdChannelObject?.split('-').length === 1
            ? `${userDetail.orgId}-${sendBirdChannelObject}`
            : `${userDetail.orgId}-${responseOfCreatedChannel.Id}`,

        // add user dynamically :
        users,
      };

      this.messagingAWSService.updateChannelUserList(userListOfDetail, `${userDetail.orgId}`).subscribe((data: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        data
          ? this.SendbirdServiceService.getMessagesFromChannelUrlPromise(userListOfDetail.sendbirdChannelUrl).then(
              (dataOfExistingChannel: any) => {
                if (dataOfExistingChannel.channelInfo.users) {
                  this.sendbirdparticiapant = dataOfExistingChannel.channelInfo.users;

                  this.listOfTaggedTeamMembers = dataOfExistingChannel.channelInfo.users;
                }
              }
            )
          : null;
        this.cdr.detectChanges();
        this.clearUsersOnMenuClose = false;
        if (this.menuTrigger) {
          this.menuTrigger.closeMenu();
        }
      });
    }
  }

  // For getting the updated data / message from react / other channel : Start
  @HostListener('document:visibilitychange', ['$event'])
  // eslint-disable-next-line sonarjs/cognitive-complexity
  public onVisibilityChange(): void {
    const channelUrl = this.sendbirdchannel?.url ? this.sendbirdchannel.url : `${this.orgId}-${this.sendbirdchannel?.Id}`;
    if (document.hidden) {
    } else {
      this.SendbirdServiceService.getChannel(channelUrl).then((channel: GroupChannel) => {
        this.sendbirdchannel = channel;
        this.SendbirdServiceService.getMessagesFromChannel(
          channel,
          (error: SendbirdError, messages: Array<UserMessage | FileMessage | AdminMessage>) => {
            if (!error) {
              if (this.sendbirdMessage?.[this.sendbirdMessage?.length - 1]?.messageId !== messages?.[messages?.length - 1]?.messageId) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                this.SendbirdServiceService.markChannelAsRead(this.sendbirdchannel?.url);
                if (!this.isNewMessagePushed) {
                  this.isNewMessagePushed = true;
                  if (JSON.parse(localStorage.getItem('unreadMessageCount')) === 0) {
                    localStorage.setItem(
                      'newMessagePushedId',
                      JSON.stringify(messages[messages?.length - 1 - (channel?.unreadMessageCount - 1)]?.createdAt)
                    );
                  }
                }
                this.showMsgBtnIfScrollable();
              }
              this.sendbirdMessage = messages;
              this.cdr.markForCheck();
            }
          }
        );
      });
      this.SendbirdServiceService.registerEventHandlers((data: { event: string; data: any }) => {
        if (this.sendbirdchannel?.url && data.event === 'onMessageReceived' && data.data.channel.url === channelUrl) {
          if (!this.isNewMessagePushed) {
            this.isNewMessagePushed = true;
            if (JSON.parse(localStorage.getItem('unreadMessageCount')) === 0) {
              localStorage.setItem('newMessagePushedId', JSON.stringify(data.data.message.createdAt));
            }
          }
          this.sendbirdMessage.push(data.data.message);
          this.showMsgBtnIfScrollable();
          this.SendbirdServiceService.markChannelAsRead(this.sendbirdchannel?.url);
          // When a message is received and loaded onto a page, it automatically scrolls :
          //setTimeout(() => this.scrollToBottom());
          this.cdr.markForCheck();
        }
      });
      this.cdr.markForCheck();
    }
  }
  // For getting the updated data / message from react / other channel : End

  // For remove the created channel and abandoned process :
  public deleteCreatedChannelFromAWS(): void {
    let removeChannelCreated: { sendbirdChannelUrl: string } = null;
    if (this.orgId && this.caseId) {
      this.nextButtonClickFlag = false;
      removeChannelCreated = {
        sendbirdChannelUrl: `${this.orgId}-${this.caseId}`,
      };

      this.orgId = '';
      this.caseId = '';
      this.cdr.detectChanges();

      this.store$.dispatch(
        commonMessageAction.deleteMessageCases({
          CaseID: removeChannelCreated.sendbirdChannelUrl.split('-')[1],
          sendbirdChannelFullUrl: removeChannelCreated,
        })
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onTaggeedTeamMemberChanges(participants: SendbirdChannelUsers[]): void {
    this.listOfTaggedTeamMembers = participants;
    //this.sendbirdparticiapant = participants;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onGuestUsersChanges(participants: SendbirdGuestUser[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
    (participants && participants.length > 0) || this.listOfTaggedTeamMembers.length > 0
      ? (this.saveButtonEnableDisableFlagValue = true)
      : null;
    this.listOfGuestUsers = participants;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onListOfACCUsers(listOfUsers: AccountParticipantResponse): void {
    this.listOfUsersForAcc = listOfUsers;
  }

  public tagParticipantsClick(): void {
    this.tagParticipantsClickFlag = true;
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public clickForEditParticipants(): void {
    this.saveButtonEnableDisableFlagValue = false;
    this.editParticipantClickFlag = true;

    this.sendbirdMessageParticipantListComponent?.onCloseSearchClick();

    let SOID;

    if (this.isThirdParty) {
      SOID = this.selectedShipmentId ? this.selectedShipmentId : this.messageThread.shipment.id;
    } else {
      SOID = this.messageThread.shipment && this.messageThread.shipment.id ? this.messageThread.shipment.id : this.router.url.split('/')[2];
    }

    if (this.sendbirdparticiapant && this.sendbirdparticiapant.length > 0) {
      if (!this.router.url.includes('messages')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
        this.router.url.includes('basics') ||
        (this.router.url.includes('invoices') &&
          !this.payloadOfDialog.id &&
          !this.payloadOfDialog.invoiceName &&
          !this.payloadOfDialog.shipment?.id)
          ? this.store$.dispatch(commonMessageAction.loadAccountParticipants())
          : this.store$.dispatch(
              commonMessageAction.loadShipmentOrderParticipants({
                SOID,
                SOAccountId: this.isThirdParty
                  ? this.router.url.includes('task')
                    ? this.selectedTaskAccountId
                    : this.selectedShipmentAccountId
                  : null,
              })
            );
      } else if (this.router.url.includes('messages')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.messageThread.shipment && this.messageThread.shipment.id
          ? this.store$.dispatch(commonMessageAction.loadShipmentOrderParticipants({ SOID: this.messageThread.shipment.id }))
          : this.store$.dispatch(commonMessageAction.loadAccountParticipants());
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (this.orgId && this.caseId) || this.sendbirdExistingChannelUrl
      ? this.store$.dispatch(
          commonMessageAction.channelInfoForParticipantList({
            sendBirdChannelUrl: this.orgId && this.caseId ? `${this.orgId}-${this.caseId}` : this.sendbirdExistingChannelUrl,
          })
        )
      : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getGuestUserValue(value: boolean): void {
    this.guestUserFormValue = value;
  }

  // for not closing the ACC modal : Start
  public fireEventForStopPropagation(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
  }
  // for not closing the ACC modal : End

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getSaveButtonFlagValue(flag: boolean): void {
    this.saveButtonEnableDisableFlagValue = flag;
  }

  public getDynamicOrgMyName(): string {
    if (window.location.href.includes('staging')) {
      return `${SourceApp.TecEx}--Staging`;
    } else if (window.location.href.includes('merging')) {
      return `${SourceApp.TecEx}--Merging`;
    } else if (window.location.href.includes('localhost')) {
      return `${SourceApp.TecEx}--Local`;
    } else {
      return `${SourceApp.TecEx}`;
    }
  }

  // For getting the message real-time access : Start
  public registerEventHandlers(): void {
    this.SendbirdServiceService.registerEventHandlers(
      (data: {
        event: string;
        data: { channel: GroupChannel; message: FileMessage | UserMessage | AdminMessage | SendbirdMessagePayload };
      }) => {
        if (this.sendbirdchannel?.url && data.event === 'onMessageReceived' && data.data.channel.url === this.sendbirdchannel.url) {
          if (!this.isNewMessagePushed) {
            this.isNewMessagePushed = true;
            if (JSON.parse(localStorage.getItem('unreadMessageCount')) === 0) {
              localStorage.setItem('newMessagePushedId', JSON.stringify(data.data.message.createdAt));
            }
          }
          this.sendbirdMessage.push(data.data.message);
          this.SendbirdServiceService.markChannelAsRead(this.sendbirdchannel?.url);
          this.showMsgBtnIfScrollable();
          // When a message is received and loaded onto a page, it automatically scrolls :
          //setTimeout(() => this.scrollToBottom());

          this.cdr.markForCheck();
        }
      }
    );
  }

  public checkOutOfOffice$(allUsers: any): Observable<boolean> {
    const participantArray = this.mapParticipantsAndUsers(this.sendbirdparticiapant, this.allParticipants);
    if (participantArray) {
      this.outOfOfficeUsers = [];
      let userArray = [];
      userArray = userArray.concat(allUsers.ACTecexPeople, allUsers.ThirdPartyPeople, allUsers.ClientUsers);
      participantArray.forEach((participant) => {
        userArray.forEach((user) => {
          if (user?.UserID && participant.id === user?.UserID && user.OutOfOffice) {
            // Check is user isn't on channel and is standby for an OOO user
            if (user.StandByUser === this.userDetail.id && !participantArray.find((part) => part.id === this.userDetail.id)) {
              this.isStandbyUser = true;
            }
            this.outOfOfficeUsers.push({
              id: participant.id,
              name: participant.nickname,
              message: '',
              outOfOffice: user.OutOfOffice,
              standbyUser: user.StandByUser,
            });
          }
        });
      });
      return of(this.outOfOfficeUsers.length > 0);
    } else {
      return of(false);
    }
  }

  public mapParticipantsAndUsers(
    sendBirdParticipants: SendbirdChannelUsers[],
    allParticipants: Participant[]
  ): { id: string; nickname: string }[] {
    const mappedParticipants = [];
    if (sendBirdParticipants) {
      sendBirdParticipants.forEach((sendBirdParticipant) => {
        mappedParticipants.push({ id: sendBirdParticipant.id, nickname: sendBirdParticipant.nickname });
      });
    }
    const mappedIds = new Set(mappedParticipants.map((participant) => participant.id));
    if (allParticipants) {
      allParticipants.forEach((participant) => {
        if (!mappedIds.has(participant.id)) {
          mappedParticipants.push({ id: participant.id, nickname: `${participant.firstName} ${participant.lastName}` });
        }
      });
    }

    return mappedParticipants;
  }

  public generateOutOfOfficeMessage(): string {
    //Put below in en.json

    if (this.outOfOfficeUsers.length === 1) {
      return `${this.outOfOfficeUsers[0].name} is out of office and may not respond`;
    } else if (this.outOfOfficeUsers.length === 2) {
      return `${this.outOfOfficeUsers[0].name} and ${this.outOfOfficeUsers[1].name} are out of office and may not respond`;
    } else {
      //3 or more ooo
      return `${this.outOfOfficeUsers.length} or more chat members are out of office and may not respond`;
    }
  }

  public onParticipantMenuClose(): void {
    // Removing this as it's clearing participants on new channels
    // if (this.clearUsersOnMenuClose) {
    //   this.newParticipants = [];
    // }
    this.clearUsersOnMenuClose = true;
  }
}
