/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { Store } from '@ngrx/store';
import { GetMessagingTokensDataService } from '@tecex-api/data';
import * as CryptoJS from 'crypto-js';
import { from, lastValueFrom, map, Observable, switchMap } from 'rxjs';
import * as utf8 from 'utf8';
import * as uuid from 'uuid';
import {
  AdminMessage,
  FileMessage,
  FileMessageCreateParams,
  MultipleFilesMessageCreateParams,
  PreviousMessageListQueryParams,
  UserMessage,
  UserMessageCreateParams,
} from '@sendbird/chat/message';
import SendbirdChat from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelHandler,
  GroupChannelListOrder,
  GroupChannelListQuery,
  GroupChannelListQueryParams,
  GroupChannelModule,
  MyMemberStateFilter,
  UnreadChannelFilter,
} from '@sendbird/chat/groupChannel';
import { OpenChannel } from '@sendbird/chat/openChannel';
import { CONFIG_TOKEN } from '../../../../../../common/src/global/config/config.token';
import { GlobalConfig } from '../../../../../../common/src/global/config/global-config.interface';
import sigV4Client from '../../../../../../common/src/global/helpers/signv4-helper';
import { AuthService } from '../../../../../../common/src/global/services/auth.service';
import { ErrorNotificationService } from '../../../../../../common/src/global/services/error-notification.service';
import { TeamMemberService } from '../../../../../../common/src/global/services/team-member.service';
import { SendbirdChannelsList } from '../../../interfaces/messages/sendbird-channel-list.interface';
import { SendbirdMention } from '../../../interfaces/messages/sendbird-mention.interface';
import { CommonMessagesService } from '../../common-messages/services/common-messages.service';
import * as commonMessageReducer from '../../message-thread/reducers';
import { SendbirdChannel } from '../../../interfaces/messages/sendbird-channel';
interface CredentialsData {
  region: string;
  credentials: {
    AccessKeyId: string;
    Expiration: string;
    SecretAccessKey: string;
    SessionToken: string;
    sendbirdApiToken: string;
    sendBirdAppId: string;
    awsMessagingApiBaseUrl: string;
    serverTimeOffset: number;
  };
  orgId: string;
  bucketName: string;
}

export interface ChannelAndMessage {
  channel: SendbirdChannel;
  messages: FileMessage[] | UserMessage[] | AdminMessage[];
  channelInfo?: SendbirdChannelInfo;
}

export interface SendbirdChannelUsers {
  id: string;
  email: string;
  nickname: string;
  isMuted: boolean;
  userType: string;
  userRole: string;
  affiliationType: string;
  userCompany: string;
  profileUrlWebApp: string;
  OutOfOffice?: boolean;
}

export interface SendbirdChannelInfo {
  orgId: string;
  orgMyName: string;
  caseId: string;
  accountId: string;
  sendbirdChannelUrl: string;
  emailTicketId: string;
  channelName: string;
  clientName: string;
  lastActivityDate: string;
  lastModifiedBy: number;
  lastEmailSent: Date;
  createdDate: Date;
  parentObject: string;
  parentId: string;
  directRefUrl: string;
  accountTzLocation: string;
  channelStatus: string;
  reOpenEnabled: boolean;
  businessUnit: string;
  parentName: string;
  guestUserEnabled: boolean;
  users: SendbirdChannelUsers[];
  isUnread?: boolean;
  shipmentOrderId: string;
  id?: string;
}
@Injectable({
  providedIn: 'root',
})
export class SendbirdService {
  public sb: any;
  public APP_ID = this.config.appId;
  public credentialsData: CredentialsData;
  public S3BucketName = '';
  public AccessKeyId = '';
  public Expiration = '';
  public RequestId = '';
  public SecretAccessKey = '';
  public SendBirdToken = '';
  public SessionToken = '';
  public userDetail = undefined;
  public currentSelectedChannel: GroupChannel;
  public connected: boolean;
  public lastApicallTime: Date;
  public encodedToken: any;
  public SendBirdAppId = '';
  public awsMessagingApiBaseUrl = '';
  public salesforceServerTime = '';
  public serverTimeOffset = 0;

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    private readonly http: HttpClient,
    private readonly errorNotificationService: ErrorNotificationService,
    public authService: AuthService,
    public getMessagingTokensService: GetMessagingTokensDataService,
    public commonMessageService: CommonMessagesService,
    private readonly store$: Store<commonMessageReducer.AppState>,
    public teamMemberService: TeamMemberService
  ) {
    this.credentialsData;
    this.userDetail;
  }

  public getDecodedTokens() {
    return this.credentialsData;
  }

  public isTokenIsAboutToExpire(expiration: string) {
    if (!this.lastApicallTime) {
      this.lastApicallTime = new Date();
    }
    const timeNow = new Date();
    const tokenExpiration = new Date(expiration);
    const interval = (tokenExpiration.getTime() - this.lastApicallTime.getTime()) / 2;
    const delta = timeNow.getTime() - this.lastApicallTime.getTime();
    return true ? interval < delta : false;
  }

  public async getAwsTokens$() {
    if (!this.getDecodedTokens() || (this.getDecodedTokens() && this.isTokenIsAboutToExpire(this.credentialsData.credentials.Expiration))) {
      this.lastApicallTime = new Date();
      this.userDetail = await lastValueFrom(this.authService.getUser$());
      this.encodedToken = await lastValueFrom(
        this.getMessagingTokensService.getMessagingTokens({
          Accesstoken: this.userDetail.accessToken,
        })
      );
      this.serverTimeOffset = this.getOffset(Date.now(), this.encodedToken);
      return this.tokenDecoder(this.encodedToken);
    } else {
      return this.tokenDecoder(this.encodedToken);
    }
  }

  public async tokenDecoder(tokens) {
    const accessToken = this.userDetail.accessToken.slice(0, 16);
    const accessTokenKey = this.commonMessageService.asciiToHex(accessToken);

    const S3BucketNameIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.S3BucketName).slice(0, 32));
    const S3BucketNameCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.S3BucketName).slice(32));
    this.S3BucketName = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, S3BucketNameIv, S3BucketNameCipherText);

    const AccessKeyIdIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.AccessKeyId).slice(0, 32));
    const AccessKeyIdCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.AccessKeyId).slice(32));
    this.AccessKeyId = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, AccessKeyIdIv, AccessKeyIdCipherText);

    const ExpirationIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.Expiration).slice(0, 32));
    const ExpirationCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.Expiration).slice(32));
    this.Expiration = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, ExpirationIv, ExpirationCipherText);

    const RequestIdIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.RequestId).slice(0, 32));
    const RequestIdCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.RequestId).slice(32));
    this.RequestId = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, RequestIdIv, RequestIdCipherText);

    const SecretAccessKeyIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SecretAccessKey).slice(0, 32));
    const SecretAccessKeyCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SecretAccessKey).slice(32));
    this.SecretAccessKey = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, SecretAccessKeyIv, SecretAccessKeyCipherText);

    const SendBirdTokenIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SendBirdToken).slice(0, 32));
    const SendBirdTokenCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SendBirdToken).slice(32));
    this.SendBirdToken = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, SendBirdTokenIv, SendBirdTokenCipherText);

    const SessionTokenIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SessionToken).slice(0, 32));
    const SessionTokenCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SessionToken).slice(32));
    this.SessionToken = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, SessionTokenIv, SessionTokenCipherText);

    const SendBirdAppIdIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SendBirdAppId).slice(0, 32));
    const SendBirdAppIdCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SendBirdAppId).slice(32));
    this.SendBirdAppId = this.commonMessageService.decrypt_core_AES_CBC(accessTokenKey, SendBirdAppIdIv, SendBirdAppIdCipherText);

    const awsMessagingApiBaseUrlIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.AWSBaseUrl).slice(0, 32));
    const awsMessagingApiBaseUrlCipherText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.AWSBaseUrl).slice(32));
    this.awsMessagingApiBaseUrl = `${this.commonMessageService.decrypt_core_AES_CBC(
      accessTokenKey,
      awsMessagingApiBaseUrlIv,
      awsMessagingApiBaseUrlCipherText
    )}/`;

    // For salesforce server timezone getting and updating the current timezone :
    const SalesforceServerTimeIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SalesforceServerTime).slice(0, 32));
    const SalesforceServerTimeInText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(tokens.SalesforceServerTime).slice(32));
    this.salesforceServerTime = this.commonMessageService.decrypt_core_AES_CBC(
      accessTokenKey,
      SalesforceServerTimeIv,
      SalesforceServerTimeInText
    );

    this.credentialsData = {
      region: 'eu-central-1',
      credentials: {
        AccessKeyId: this.AccessKeyId,
        Expiration: this.Expiration,
        SecretAccessKey: this.SecretAccessKey,
        SessionToken: this.SessionToken,
        sendbirdApiToken: this.SendBirdToken,
        sendBirdAppId: this.SendBirdAppId,
        awsMessagingApiBaseUrl: this.awsMessagingApiBaseUrl,
        serverTimeOffset: this.serverTimeOffset,
      },
      orgId: this.userDetail.orgId,
      bucketName: this.S3BucketName,
    };
    if (!this.sb) {
      const params = {
        appId: this.SendBirdAppId,
        modules: [new GroupChannelModule()],
      };
      this.sb = SendbirdChat.init(params);
      const user = await this.sb.connect(this.userDetail.id, this.SendBirdToken);
      this.connected = true;
    }
    return this.credentialsData;
  }

  public getOffset(dateEpochNow: number, token: any) {
    const accessToken = this.userDetail.accessToken.slice(0, 16);
    const accessTokenKey = this.commonMessageService.asciiToHex(accessToken);
    const SalesforceServerTimeIv = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(token.SalesforceServerTime).slice(0, 32));
    const SalesforceServerTimeInText = CryptoJS.enc.Hex.parse(this.commonMessageService.base64ToHex(token.SalesforceServerTime).slice(32));
    this.salesforceServerTime = this.commonMessageService.decrypt_core_AES_CBC(
      accessTokenKey,
      SalesforceServerTimeIv,
      SalesforceServerTimeInText
    );
    return dateEpochNow > Number(this.salesforceServerTime)
      ? 0 - (dateEpochNow - Number(this.salesforceServerTime))
      : Number(this.salesforceServerTime) - dateEpochNow;
  }

  async connect(userId?: string, token?: string, callback?): Promise<void> {
    if (this.sb) {
      try {
        const user = await this.sb.connect(this.userDetail.id, this.SendBirdToken);
        this.connected = true;
      } catch (error) {
        callback(this.userDetail, error);
      }
    }
  }

  disconnect() {
    if (this.sb) {
      this.sb.disconnect();
    }
  }

  isConnected() {
    return this.sb && this.sb.currentUser && this.sb.currentUser.userId;
  }

  getConnectedUser() {
    return this.sb && this.sb.currentUser ? this.sb.currentUser : null;
  }

  uploadToS3 = async (file: File, credentialsData: any, metadata: any, progressCallback): Promise<any> => {
    const tokens = await this.getAwsTokens$();

    try {
      const s3Client = new S3Client({
        credentials: {
          accessKeyId: credentialsData?.credentials.AccessKeyId,
          secretAccessKey: credentialsData?.credentials.SecretAccessKey,
          sessionToken: credentialsData?.credentials.SessionToken,
        },
        region: credentialsData.region,
        systemClockOffset: this.serverTimeOffset,
      });

      const limitedFileName =
        file.name.slice(0, Math.max(0, file.name.lastIndexOf('.'))).slice(0, 128) +
        file.name.slice(Math.max(0, file.name.lastIndexOf('.')));

      const parallelUploadS3 = new Upload({
        client: s3Client,
        params: {
          Bucket: credentialsData.bucketName,
          Key: `files/${metadata.s3UUID}/${this.encodeRFC5987ValueChars(file.name)}`,
          Body: file,
          // eslint-disable-next-line unicorn/no-hex-escape
          ContentDisposition: `attachment; filename=${limitedFileName.replaceAll(
            /[^\w.-]/g,
            '_'
          )}; filename*=UTF-8''${this.encodeRFC5987ValueChars(limitedFileName)}`,
          ContentType: file.type,
          Metadata: {
            sendbirdChannelUrl: `${metadata.sendbirdChannelUrl}`,
            filename: `${metadata.filename}`,
            type: `${metadata.type}`,
            size: `${metadata.size}`,
            s3UUID: `${metadata.s3UUID}`,
            dateUploaded: `${metadata.dateUploaded}`,
            uploadedById: `${metadata.uploadedById}`,
            uploadedByNickname: `${this.encodeRFC5987ValueChars(metadata.uploadedByNickname)}`,
            uploadedByEmail: `${metadata.uploadedByEmail}`,
            uploadedBySystem: `${metadata.uploadedBySystem}`,
            availableToClient: `${metadata.availableToClient}`,
          },
        },
        leavePartsOnError: true,
      });
      parallelUploadS3.on('httpUploadProgress', ({ loaded, total }) => {
        progressCallback(Math.floor((loaded * 100) / total));
      });
      await parallelUploadS3.done();
      return `Uploaded ${file.name} successfully.`;
    } catch (error) {
      this.errorNotificationService.notifyAboutError(error, 'ERROR.ERROR_UPLOADING_FILE');
    }
  };

  registerEventHandlers(callback: any) {
    this.getAwsTokens$().then((data) => {
      const groupChannelHandler = new GroupChannelHandler({
        onMessageReceived: (channel: any, message: any) => {
          callback({
            event: 'onMessageReceived',
            data: {
              channel,
              message,
            },
          });
        },
      });
      this.sb.groupChannel.addGroupChannelHandler(this.userDetail.id, groupChannelHandler);
    });
  }

  unRegisterEventHandlers() {
    this.getAwsTokens$().then((data) => {
      this.sb.groupChannel.removeGroupChannelHandler(this.userDetail.id);
      //this.sb.removeChannelHandler(this.userDetail.id);
    });
  }

  async getChannelsForShipmentPage() {
    await this.getAwsTokens$();
    const params: GroupChannelListQueryParams = {
      includeEmpty: true,
      myMemberStateFilter: MyMemberStateFilter.JOINED,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      limit: 100,
      unreadChannelFilter: UnreadChannelFilter.UNREAD_MESSAGE,
    };
    const listQuery: GroupChannelListQuery = this.sb.groupChannel.createMyGroupChannelListQuery(params);

    return listQuery.hasNext ? await listQuery.next() : [];
  }

  async getAllChannels() {
    await this.getAwsTokens$();

    const allChannels = [];
    const params: GroupChannelListQueryParams = {
      includeEmpty: true,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      myMemberStateFilter: MyMemberStateFilter.ALL,
      limit: 100,
    };
    const listQuery: GroupChannelListQuery = this.sb.groupChannel.createMyGroupChannelListQuery(params);

    while (listQuery.hasNext) {
      allChannels.push(await listQuery.next());
    }
    return allChannels.flat();
  }

  getMyGroupChannels() {
    return this.getChannelsForShipmentPage().then((data) => data);
  }

  public async getChannel(channelUrl: string, isOpenChannel = false): Promise<GroupChannel> {
    if (!this.isConnected()) {
      this.connect();
    }
    if (channelUrl?.split('-')[0] !== this.userDetail.orgId) {
      const groupChannelCopy = channelUrl;
      channelUrl = `${this.userDetail.orgId}-${groupChannelCopy}`;
    }
    return this.sb.groupChannel.getChannel(channelUrl).then((groupChannel) => {
      this.currentSelectedChannel = groupChannel;
      return groupChannel;
    });
  }

  isuserJoined(groupChannel: GroupChannel, userId?: string): boolean {
    if (!userId) {
      userId = this.userDetail.id;
    }
    if (!groupChannel.url) {
      groupChannel = this.currentSelectedChannel;
    }

    return groupChannel.members?.findIndex((user) => user.userId === userId) >= 0 ? true : false;
  }

  getMessagesFromChannel(groupChannel: GroupChannel, callback: any) {
    if (!this.isConnected()) {
      this.connect();
    }
    this.currentSelectedChannel = groupChannel;
    const params: PreviousMessageListQueryParams = {
      limit: 100,
      includeMetaArray: true,
      includeReactions: true,
    };

    const listQuery = groupChannel.createPreviousMessageListQuery(params);
    listQuery
      .load()
      .then((messages) => {
        callback(null, messages);
      })

      .catch((error) => {
        callback(error);
      });
  }

  findObjectAndGetValue(messages, targetId, condition) {
    const targetIndex = messages.findIndex((obj) => obj?.createdAt === targetId);
    if (targetIndex !== -1) {
      for (let i = targetIndex + 1; i < messages.length; i++) {
        if (condition(messages[i])) {
          return messages[i];
        }
      }
    }
    return null;
  }

  async getMessagesFromChannelUrlPromise(groupChannelUrl: string): Promise<ChannelAndMessage> {
    const token = await this.getAwsTokens$();

    if (!this.isConnected()) {
      this.connect();
    }
    if (groupChannelUrl?.split('-')[0] !== this.userDetail.orgId) {
      const groupChannelCopy = groupChannelUrl;
      groupChannelUrl = `${this.userDetail.orgId}-${groupChannelCopy}`;
    }

    const channel = await this.sb.groupChannel.getChannel(groupChannelUrl);
    const lastReadMessage = channel?.myLastRead;
    localStorage.setItem('newMessagePushedId', JSON.stringify(0));
    localStorage.setItem('unreadMessageCount', JSON.stringify(channel?.unreadMessageCount));
    if (this.isuserJoined(channel)) {
      // Might need to re-add
      //await this.sb.groupChannel.clearCache();
      channel.markAsRead();
    }

    const channelInformation = await lastValueFrom(this.channelInfo$(channel.url));
    const channelInfo = channelInformation;
    const params: PreviousMessageListQueryParams = {
      limit: 100,
      includeMetaArray: true,
      includeReactions: true,
    };
    const listQuery = channel.createPreviousMessageListQuery(params);

    const messages = await listQuery.load();
    const condition = (obj) => {
      return obj?.sender?.userId !== this.userDetail.id;
    };
    const lastUnreadMessage = this.findObjectAndGetValue(messages, lastReadMessage, condition);

    localStorage.setItem('lastUnreadMessage', lastUnreadMessage ? JSON.stringify(lastUnreadMessage?.createdAt) : null);

    const response = {
      channel,
      messages,
      channelInfo,
    };
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  }

  async markChannelAsRead(groupChannelUrl: string): Promise<void> {
    if (!this.isConnected()) {
      this.connect();
    }
    if (groupChannelUrl?.split('-')[0] !== this.userDetail.orgId) {
      const groupChannelCopy = groupChannelUrl;
      groupChannelUrl = `${this.userDetail.orgId}-${groupChannelCopy}`;
    }

    const channel = await this.sb.groupChannel.getChannel(groupChannelUrl);
    if (this.isuserJoined(channel)) {
      await this.sb.clearCache();
      // await this.sb.groupChannel.clearCache();
      channel.markAsRead();
    }
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      // eslint-disable-next-line unicorn/prefer-code-point
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  removeStartExtraSpace = (str) => {
    const token = '<p>&nbsp;</p>';

    while (str.startsWith(token)) {
      str = str.replace(token, '');
    }
    return str;
  };
  removeEndExtraSpace = (str) => {
    const token = '<p>&nbsp;</p>';

    while (str.endsWith(token)) {
      str = str.replace(token, '');
    }
    return str;
  };
  public utf8_to_b64(str) {
    return btoa(utf8.encode(str));
  }

  public FileNameencodeURI(str) {
    return this.encodeRFC5987ValueChars(str);
  }

  public encodeRFC5987ValueChars(str) {
    return encodeURIComponent(str)
      .replaceAll(/['()*]/g, (c) => `%${c.codePointAt(0).toString(16)}`)
      .replaceAll(/%(7C|60|5E)/g, (str, hex) => String.fromCodePoint(Number.parseInt(hex, 16)));
  }

  public encodeEmailAddresses(str) {
    return this.encodeRFC5987ValueChars(str).replaceAll('%40', '@');
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async sendMessage(channel: GroupChannel | OpenChannel, message: any, callback: any) {
    let newdataRaw = message.body;
    const docforMention = message.body;
    const users = message.mention;
    const attachments = message.attachments;
    const progressCallback = message.callbackFunc;
    newdataRaw = newdataRaw.split(/(<img[^>]*>)/g);
    const newD = [];
    const mentionParser = new DOMParser();
    const doc = mentionParser.parseFromString(docforMention, 'text/html');
    const mention = doc.body.querySelectorAll('.mention');
    const mutedUsersId = [];
    const mentionUsersId = [];
    mention.forEach((mentions) => {
      if (mentions.attributes['ismuted'].value === 'true') {
        mutedUsersId.push(mentions.attributes['data-user-id'].value);
        mentionUsersId.push(mentions.attributes['data-user-id'].value);
      } else {
        mentionUsersId.push(mentions.attributes['data-user-id'].value);
      }
    });
    if (mutedUsersId.length > 0) {
      this.unmuteUser$(mutedUsersId, channel.url);
    }

    const sendChat = (newdata, channel, iter, callback) => {
      if (newdata.length === iter) {
        let msgString = newD.join('');

        users?.filter((user) => {
          msgString = msgString.replace(`@${user.nickname}`, '');
        });
        const params: UserMessageCreateParams = {
          message: msgString,
          mentionedUserIds: mentionUsersId,
        };

        if (msgString.length > 0) {
          channel
            .sendUserMessage(params)
            .onSucceeded((message) => {
              callback(null, message, mutedUsersId);
            })
            .onFailed((error) => {
              callback(error, null);
            });
        }
      } else if (iter <= newdata.length && newdata[iter]?.startsWith('<img')) {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(newdata[iter], 'text/html');
        const imgAttribute = parsedHtml.querySelector('img');
        const fileStr = imgAttribute.getAttribute('src');
        const filename = imgAttribute.getAttribute('alt');
        const file = this.dataURLtoFile(fileStr, filename);

        const params: FileMessageCreateParams = {
          file: file, // Or .fileUrl  = FILE_URL (You can also send a file message with a file URL.)
          fileName: file.name,
          fileSize: file.size,
          customType: 'file',
          thumbnailSizes: [
            { maxWidth: 100, maxHeight: 100 },
            { maxWidth: 200, maxHeight: 200 },
          ], // Add the maximum sizes of thumbnail images (allowed number of thumbnail images: 3).
          mimeType: file.type,
        };
        const fileNameBase64 = this.utf8_to_b64(filename);
        const s3Uuid = uuid.v4();
        const metadata = {
          sendbirdChannelUrl: channel.url,
          filename: `${fileNameBase64}`,
          type: file.type,
          size: file.size,
          s3UUID: s3Uuid,
          dateUploaded: new Date().toISOString(),
          uploadedById: `${this.userDetail.id}`,
          uploadedByNickname: `${this.userDetail.name}`,
          uploadedByEmail: `${this.encodeEmailAddresses(this.userDetail.email)}`,
          uploadedBySystem: `${window.location.host}`,
          availableToClient: 'true',
        };
        this.uploadToS3(file, this.credentialsData, metadata, progressCallback);
        channel
          .sendFileMessage(params)
          .onSucceeded((fileMessage) => {
            if (fileMessage) {
              iter += 1;
              // eslint-disable-next-line max-len
              const script = `
                <img id="${fileMessage.messageId}_thumbnail"  src=${fileMessage.thumbnails[1].url}>`;

              newD.push(script.replaceAll(/\s+/g, ' ').trim());
              sendChat(newdata, channel, iter, callback);
            } else {
              callback(null, fileMessage, mutedUsersId);
            }
          })
          .onFailed((error) => {
            callback(error, null);
          });
      } else {
        let data = newdata[iter];
        data = data ? this.removeStartExtraSpace(data) : data;
        data = data ? this.removeEndExtraSpace(data) : data;
        if (data !== '' || data !== undefined) {
          iter += 1;
          newD.push(data);
          sendChat(newdata, channel, iter, callback);
        }
      }
    };
    if (newdataRaw.length > 0) {
      sendChat(newdataRaw, channel, 0, callback);
    }

    if (attachments) {
      const fileParams: MultipleFilesMessageCreateParams = { customType: 'awsFile', fileInfoList: [] };

      for (const element of attachments) {
        // eslint-disable-next-line unicorn/no-hex-escape
        const file_name = this.utf8_to_b64(element.name);
        const s3Uuid = uuid.v4();
        const metadata = {
          sendbirdChannelUrl: channel.url,
          filename: `${file_name}`,
          type: element.type,
          size: element.size,
          s3UUID: s3Uuid,
          dateUploaded: new Date().toISOString(),
          uploadedById: `${this.userDetail.id}`,
          uploadedByNickname: `${this.userDetail.name}`,
          uploadedByEmail: `${this.encodeEmailAddresses(this.userDetail.email)}`,
          uploadedBySystem: `${window.location.host}`,
          availableToClient: 'true',
        };
        const params: FileMessageCreateParams = {
          fileUrl: `${s3Uuid}/${this.FileNameencodeURI(element.name)}`,
          fileName: element.name,
          fileSize: element.size,
          mimeType: element.type,
          customType: 'awsFile',
        };

        fileParams.fileInfoList.push(params);

        // First wait for upload in AWS S3 and then message send to sendbird and display as well :
        await this.uploadToS3(element, this.credentialsData, metadata, progressCallback);
      }

      if (fileParams.fileInfoList.length > 1) {
        (channel as GroupChannel)
          .sendMultipleFilesMessage(fileParams)
          .onSucceeded((message) => {
            callback(null, message);
          })
          .onFailed((error) => {
            callback(error, null);
          });
      } else {
        channel
          .sendFileMessage(fileParams.fileInfoList[0])
          .onSucceeded((message) => {
            callback(null, message);
          })
          .onFailed((error) => {
            callback(error, null);
          });
      }
    }
  }

  getspecificChannels$(queryParams?: any): Observable<SendbirdChannelsList[]> {
    return from(this.getAwsTokens$()).pipe(
      switchMap((data) => {
        this.credentialsData = data;
        const authUser = sigV4Client.newClient({
          accessKey: this.AccessKeyId,
          secretKey: this.SecretAccessKey,
          sessionToken: this.SessionToken,
          region: 'eu-central-1',
          endpoint: this.awsMessagingApiBaseUrl,
          serverTimeOffset: this.serverTimeOffset,
        });
        if (!queryParams) {
          queryParams = {
            accountId: this.userDetail.accountId,
          };
        }
        queryParams.orgId = this.userDetail.orgId;
        if (this.authService.thirdPartyFlag()) {
          let userIds = [];
          this.teamMemberService.getThirdPartyUserIds().subscribe((Ids) => {
            userIds = Ids;
          });
          queryParams.userIdList = userIds ? userIds.toString() : '';
        }

        const method = 'get';
        const path = `${this.userDetail.orgId}/getListOfChannels`;
        const header = { 'Content-Type': 'application/json;' };

        const response = authUser.signRequest({
          method,
          path,
          header,
          queryParams,
        });
        return this.http
          .get<SendbirdChannelsList[]>(response.url, { headers: response.headers })
          .pipe(map((channels) => channels?.filter((channel) => channel?.subject !== '-= DRAFT =-')));
      })
    );
  }

  getMentionslist$(cahnnelUrl: string): Observable<SendbirdMention> {
    // This is for getAwsToken get and set fir getMentionList API :
    return from(this.getAwsTokens$()).pipe(
      switchMap((data) => {
        this.credentialsData = data;
        const authUser = sigV4Client.newClient({
          accessKey: this.AccessKeyId,
          secretKey: this.SecretAccessKey,
          sessionToken: this.SessionToken,
          region: 'eu-central-1',
          endpoint: this.credentialsData.credentials.awsMessagingApiBaseUrl,
          serverTimeOffset: this.serverTimeOffset,
        });

        let queryParams = {};

        const method = 'get';
        const path = `angular/getMentionList/${cahnnelUrl}`;
        const header = { 'Content-Type': 'application/json;' };
        queryParams = {};
        const response = authUser.signRequest({
          method,
          path,
          header,
          queryParams,
        });
        return this.http.get<SendbirdMention>(response.url, { headers: response.headers });
      })
    );
  }

  downloadS3Message = async (event) => {
    const tokens = await this.getAwsTokens$();
    const s3ClientDownload = new S3Client({
      credentials: {
        accessKeyId: this.AccessKeyId,
        secretAccessKey: this.SecretAccessKey,
        sessionToken: this.SessionToken,
      },
      region: 'eu-central-1',
      systemClockOffset: this.serverTimeOffset,
    });

    const getObjectParams = { Bucket: this.S3BucketName, Key: `files/${event.objectKey}` };
    const command = new GetObjectCommand(getObjectParams);
    // This is for signed URL getting and set the expiry time set from `expiresIn` :
    const correctDate = new Date(Date.now() + this.serverTimeOffset);
    const url = await getSignedUrl(s3ClientDownload, command, { expiresIn: 10_800, signingDate: correctDate });
    window.open(url);
  };

  public async getUnreadChannels(queryParams?) {
    const tokens = await this.getAwsTokens$();
    const authUser = sigV4Client.newClient({
      accessKey: this.credentialsData.credentials.AccessKeyId,
      secretKey: this.credentialsData.credentials.SecretAccessKey,
      sessionToken: this.credentialsData.credentials.SessionToken,
      region: 'eu-central-1',
      endpoint: this.credentialsData.credentials.awsMessagingApiBaseUrl,
      serverTimeOffset: this.serverTimeOffset,
    });
    const method = 'get';
    const path = `${this.userDetail.orgId}/getListOfChannels`;
    const header = { 'Content-Type': 'application/json;' };
    queryParams = {
      accountId: this.userDetail.accountId,
      orgId: this.userDetail.orgId,
    };
    const response = authUser.signRequest({
      method,
      path,
      header,
      queryParams,
    });
    //await this.sb.groupChannel.clearCache();

    const params: GroupChannelListQueryParams = {
      includeEmpty: true,
      myMemberStateFilter: MyMemberStateFilter.JOINED,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      limit: 100,
      unreadChannelFilter: UnreadChannelFilter.UNREAD_MESSAGE,
    };
    const listQuery: GroupChannelListQuery = this.sb.groupChannel.createMyGroupChannelListQuery(params);
    let channels = [];
    if (listQuery?.hasNext) {
      channels = await listQuery.next();
    }
    const channelsListUrl = [];
    channels.forEach((element) => channelsListUrl.push(element.url));
    return await this.checkDefault(channels).then((data: any) => {
      return lastValueFrom(this.channelInfoList$(data));
    });
  }

  public async checkDefault(channels) {
    const channelsListUrl = [];
    return new Promise((resolve) => {
      this.getspecificChannels$().subscribe((data) => {
        if (data) {
          data.forEach((filteredChannels) => {
            channels.forEach((element) => {
              if (filteredChannels.id === element.url) {
                channelsListUrl.push(element.url);
              }
            });
          });
          resolve(channelsListUrl);
        } else {
          resolve([]);
        }
      });
    });
  }

  public async getunreadChannels$(queryParams?) {
    await this.getAwsTokens$();
    return this.getUnreadChannels();
  }

  public unmuteUser$(user_ids, sendbirdChannelUrl) {
    const authUser = sigV4Client.newClient({
      accessKey: this.AccessKeyId,
      secretKey: this.SecretAccessKey,
      sessionToken: this.SessionToken,
      region: 'eu-central-1',
      endpoint: this.awsMessagingApiBaseUrl,
      serverTimeOffset: this.serverTimeOffset,
    });
    const method = 'patch';
    const path = `${this.userDetail.orgId}/unMuteUser`;
    const header = { 'Content-Type': 'application/json;' };
    const body = {
      sendbirdChannelUrl,
      user_ids,
    };
    const response = authUser.signRequest({
      method,
      path,
      header,
      body,
    });

    return this.http.patch<any>(response.url, body, { headers: response.headers }).subscribe();
  }

  public channelInfo$(channel_url: string): Observable<SendbirdChannelInfo> {
    const authUser = sigV4Client.newClient({
      accessKey: this.AccessKeyId,
      secretKey: this.SecretAccessKey,
      sessionToken: this.SessionToken,
      region: 'eu-central-1',
      endpoint: this.awsMessagingApiBaseUrl,
      serverTimeOffset: this.serverTimeOffset,
    });
    const method = 'get';
    const path = `${this.userDetail.orgId}/channelInfo/${channel_url}`;
    const header = { 'Content-Type': 'application/json;' };
    const response = authUser.signRequest({
      method,
      path,
      header,
    });

    return this.http.get<SendbirdChannelInfo>(response.url, { headers: response.headers });
  }

  public channelInfoList$(listofChannelUrls: string[]): Observable<SendbirdChannelInfo[]> {
    const authUser = sigV4Client.newClient({
      accessKey: this.AccessKeyId,
      secretKey: this.SecretAccessKey,
      sessionToken: this.SessionToken,
      region: 'eu-central-1',
      endpoint: this.awsMessagingApiBaseUrl,
      serverTimeOffset: this.serverTimeOffset,
    });
    const method = 'post';
    const path = `${this.userDetail.orgId}/channelInfoList`;
    const header = { 'Content-Type': 'application/json;' };
    const body = {
      sendbirdChannelUrls: listofChannelUrls,
    };
    const response = authUser.signRequest({
      method,
      path,
      header,
      body,
    });

    return this.http.post<SendbirdChannelInfo[]>(response.url, body, { headers: response.headers });
  }

  async mappedChannelInfoForAllAccountLevelChanels() {
    const AccountLevelChannelsUrlList = await lastValueFrom(
      this.getspecificChannels$().pipe(
        map((accountChannels) => {
          if (accountChannels) {
            return accountChannels.map((channels) => channels.id);
          }
          return [];
        })
      )
    );
    return lastValueFrom(this.channelInfoList$(AccountLevelChannelsUrlList));
  }

  // For unread channels getting from sendbird API call :
  public async unReadChannelForCommon() {
    const tokens = await this.getAwsTokens$();

    // await this.sb.groupChannel.clearCache();

    const params: GroupChannelListQueryParams = {
      includeEmpty: true,
      myMemberStateFilter: MyMemberStateFilter.JOINED,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      limit: 100,
      unreadChannelFilter: UnreadChannelFilter.UNREAD_MESSAGE,
    };
    const listQuery: GroupChannelListQuery = this.sb.groupChannel.createMyGroupChannelListQuery(params);
    let channels = [];
    if (listQuery.hasNext) {
      channels = await listQuery.next();
    }

    const channelsListUrl = [];
    channels.forEach((element) => channelsListUrl.push(element.url));

    return await lastValueFrom(this.channelInfoList$(channelsListUrl));
  }

  // For getting the unread messages / channels from `getListOfChannel` for specific SO ID wise :
  public async getUnreadChannelsFromSelectedSOID(shipmentId: string) {
    const data = await this.getAwsTokens$();
    const authUser = sigV4Client.newClient({
      accessKey: data.credentials.AccessKeyId,
      secretKey: data.credentials.SecretAccessKey,
      sessionToken: data.credentials.SessionToken,
      region: data.region,
      endpoint: data.credentials.awsMessagingApiBaseUrl,
      serverTimeOffset: this.serverTimeOffset,
    });
    const method = 'get';
    const path = `${data.orgId}/getListOfChannels`;
    const header = { 'Content-Type': 'application/json;' };
    let queryParams = {};
    if (this.authService.thirdPartyFlag()) {
      let userIds = [];
      this.teamMemberService.getThirdPartyUserIds().subscribe((Ids) => {
        userIds = Ids;
      });
      queryParams = {
        orgId: data.orgId,
        shipmentOrderId: shipmentId,
        userIdList: userIds ? userIds.toString() : '',
      };
    } else {
      queryParams = {
        orgId: data.orgId,
        shipmentOrderId: shipmentId,
      };
    }
    const response = authUser.signRequest({
      method,
      path,
      header,
      queryParams,
    });
    const channels = await this.http.get<SendbirdChannelsList[]>(response.url, { headers: response.headers }).toPromise();

    const channelsListUrl = [];
    channels?.forEach((element) => channelsListUrl.push(element.id));

    return await lastValueFrom(this.channelInfoList$(channelsListUrl));
  }

  getinstanse() {
    return this.sb;
  }
}
