import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@ngneat/reactive-forms';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { AccountParticipantResponse } from '@tecex-api/data/model/accountParticipantResponse';
import { SOTecexPeopleResponseItem } from '@tecex-api/data/model/sOTecexPeopleResponseItem';
import { ACTecexPeopleResponseItem, ClientContactsResponseItem, ClientUsersResponseItem } from '@tecex-api/data';
import { User } from '../../../../../global/interfaces/user.interface';
import { AuthService } from '../../../../../global/services/auth.service';
import {
  optimizedToggleOpacityAnimation,
  toggleAnimation,
  toggleOpacityAnimation,
} from '../../../../animations/toggle-open-close.animation';
import { SourceApp } from '../../../../enums/source-app.enum';
import { AffiliateUser, ClientUser, InternalUser, Participant } from '../../../../interfaces/participant.interface';
import { MessageDialogPayload } from '../../../message-dialog/types/message-dialog-payload.interface';
import * as commonMessageReducers from '../../reducers';
import { SendbirdChannelInfo, SendbirdChannelUsers } from '../../services/sendbird-message.service';
import { SendbirdGuestUser } from '../../../../interfaces/messages/sendbird-guest-user';
import { MessageThreadDetails } from '../../../../interfaces/messages/message-thread-details.interface';
import { SendbirdMessageParticipantItemComponent } from '../sendbird-message-participant-item/sendbird-message-participant-item.component';
import { SearchFieldComponent } from '../../../search-field/search-field.component';
import { TooltipDirective } from '../../../tooltip/tooltip.directive';
import { MessagesRoles } from '../../../common-messages/enums/messages-roles.enum';

const GROUP_HEADER_HEIGHT = 32;

@Component({
  selector: 'app-sendbird-message-participant-list',
  templateUrl: './sendbird-message-participant-list.component.html',
  styleUrls: ['./sendbird-message-participant-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleAnimation(GROUP_HEADER_HEIGHT), optimizedToggleOpacityAnimation, toggleOpacityAnimation],
  standalone: true,
  imports: [
    NgIf,
    SearchFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    NgFor,
    SendbirdMessageParticipantItemComponent,
    MatButtonModule,
    SvgIconComponent,
    NgClass,
    TooltipDirective,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SendbirdMessageParticipantListComponent implements OnInit, OnDestroy {
  @Input() public title?: string;
  @Input() public teamMembers: Participant[];
  @Input() public participants: Participant[];
  @Input() public sendbirdParticipants: SendbirdChannelUsers[];
  @Input() public messageDialogDetail: MessageDialogPayload;
  @Input() public guestUserDisableFlag: boolean;
  @Input() public guestUserFromChannelInfo: boolean;
  @Input() public messageThreadDetail: MessageThreadDetails;
  @Input() public lengthOfMessageForUpdateParticipants: number;
  @Input() public taggedRoles: string[];

  @Output() public add = new EventEmitter<Participant | any>();
  @Output() public remove = new EventEmitter<Participant | any>();
  @Output() public taggedTeamMembersList = new EventEmitter<any[]>();
  @Output() public guestUsersList = new EventEmitter<any>();
  @Output() public listOfACCUsers = new EventEmitter<any>();
  @Output() public guestUserValueChange = new EventEmitter<any>();
  @Output() public saveButtonFlagChanges = new EventEmitter<any>(false);

  public teamMembersToDisplay: Participant[] = [];
  public defaultTeamMembersToDisplay: Participant[] = [];
  public taggedTeamMembersToDisplay: any = [];
  public searchedTeamMembersToDisplay: Participant[] = [];

  public searchFieldControl = new FormControl<string>('');
  public cancelControl = new FormControl<string>('');
  public keyword$ = this.searchFieldControl.value$;
  public isOpenForInternalUser = false;
  public isOpenForClientUser = false;
  public isOpenForClientContact = false;
  public isOpenForThirdPartyUsers = false;
  public companyNameForClient = '';
  public concatClientUserContacts: any;

  public guestUserEmail: string;
  public guestUserToggle: boolean;

  public Internal_Users__c: InternalUser[];
  public Client_Users__c: ClientUser[] | any;
  public Affiliate_Users__c: AffiliateUser[] | any;

  public internal_Users__c_New: InternalUser[] | any;
  public client_Users__c_New: ClientUser[] | any;
  public affiliate_Users__c_New: AffiliateUser[] | any;

  public user$: Observable<User>;

  public participantSectionName = 'TecEx';
  public loginUserDetail: User;
  public listOfGuestUsers: SendbirdGuestUser[] = [];
  public existingChannelParticipantsFlag = false;
  public channelInfoDetail$: Observable<SendbirdChannelInfo>;
  public unsubscribeChannelInfo: Subscription;
  public ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();
  public isThirdParty = this.authService.thirdPartyFlag();
  public guestUserForm: FormGroup;

  public get guestUserEmailControl(): FormControl<string> {
    return this.guestUserForm.get('guestUserEmail') as FormControl<string>;
  }

  public onAddClickForGuestUser(): void {
    const guestUserTempObject: SendbirdGuestUser = {
      id: this.guestUserEmail,
      email: this.guestUserEmail,
      nickname: this.guestUserEmail,
      isMuted: false,
      userType: 'Guest',
      userRole: null,
      userCompany: null,
      affiliationType: null,
      profileUrlWebApp: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
    };

    if (this.listOfGuestUsers.length === 0 && this.guestUserEmail) {
      this.listOfGuestUsers.push(guestUserTempObject);
      this.guestUsersList.emit(this.listOfGuestUsers);

      this.guestUserEmail = null;
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    } else if (this.guestUserEmail && !this.listOfGuestUsers.find((x: SendbirdGuestUser) => x.id.includes(this.guestUserEmail))) {
      this.listOfGuestUsers.push(guestUserTempObject);
      this.guestUsersList.emit(this.listOfGuestUsers);

      this.guestUserEmail = null;
    }
  }

  public removeForGuestUserList(index: number): void {
    this.listOfGuestUsers.splice(index, 1);
    this.guestUsersList.emit(this.listOfGuestUsers);
  }

  // new code for shipment order participants getting :
  public listOfParticipants$: Observable<AccountParticipantResponse>;

  constructor(
    public router: Router,
    private readonly store$: Store<commonMessageReducers.AppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly fb: FormBuilder
  ) {}

  public onToggleClickForInternalUser(): void {
    this.isOpenForInternalUser = !this.isOpenForInternalUser;
  }

  public onToggleClickForClientUser(): void {
    this.isOpenForClientUser = !this.isOpenForClientUser;
  }

  public onToggleClickForClientContact(): void {
    this.isOpenForClientContact = !this.isOpenForClientContact;
  }

  public onToggleClickForAffiliateUsers(): void {
    this.isOpenForThirdPartyUsers = !this.isOpenForThirdPartyUsers;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public ngOnInit(): void {
    this.user$ = this.authService.getUser$();

    this.guestUserForm = this.fb.group({
      guestUserEmail: ['', [Validators.required, Validators.email]],
    });

    // For selecting the channel info detail from store :
    this.channelInfoDetail$ = this.store$.select(commonMessageReducers.selectChannelInfoDetail);

    this.user$.subscribe((value) => {
      this.loginUserDetail = value;
    });

    if (!this.router.url.includes('messages')) {
      this.listOfParticipants$ =
        (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
        (this.router.url.includes('dashboard') &&
          this.router.url.includes('task') &&
          !this.messageDialogDetail.shipment &&
          !this.messageDialogDetail.shipment?.id) ||
        this.router.url.includes('basics') ||
        (this.router.url.includes('invoices') &&
          !this.messageDialogDetail.id &&
          !this.messageDialogDetail?.invoiceName &&
          !this.messageDialogDetail.shipment?.id) ||
        (this.messageDialogDetail.source?.type === 'NCP Invoice' &&
          this.messageDialogDetail.invoiceName &&
          !this.messageDialogDetail.shipment.id) ||
        this.router.url.includes('product-catalog') ||
        this.router.url.includes('quote-guidance')
          ? this.store$.select(commonMessageReducers.selectListOfAccountParticipant)
          : this.store$.select(commonMessageReducers.selectListOfShipmentOrderParticipant);
    } else if (this.router.url.includes('messages')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.listOfParticipants$ =
        this.messageThreadDetail.shipment && this.messageThreadDetail.shipment.id
          ? this.store$.select(commonMessageReducers.selectListOfShipmentOrderParticipant)
          : this.store$.select(commonMessageReducers.selectListOfAccountParticipant);
    }

    // For conditional based toggle button switch : [ for invoice - flag & for existing channel ]
    if (this.sendbirdParticipants === null || this.sendbirdParticipants.length === 0)
      this.guestUserToggle =
        this.router.url.includes('invoices') ||
        (this.router.url.includes('shipments-list') &&
          this.messageDialogDetail.invoiceName &&
          this.messageDialogDetail.shipment &&
          this.messageDialogDetail.shipment.id)
          ? false
          : true;
    else this.guestUserToggle = this.guestUserDisableFlag;

    this.searchFieldControl.valueChanges.subscribe((keyword) => {
      const keywordLowerCase = keyword.toLocaleLowerCase();
      this.searchedTeamMembersToDisplay = [
        ...new Set([
          ...this.sortParticipantsAlphabetically(
            this.teamMembersToDisplay.filter((teamMember: Participant) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              teamMember.firstName?.toLocaleLowerCase().startsWith(keywordLowerCase) &&
                teamMember.lastName?.toLocaleLowerCase().startsWith(keywordLowerCase);
            })
          ),
          ...this.sortParticipantsAlphabetically(
            this.teamMembersToDisplay.filter((teamMember: Participant) =>
              `${teamMember.firstName} ${teamMember.lastName}`.toLocaleLowerCase().startsWith(keywordLowerCase)
            )
          ),
          ...this.sortParticipantsAlphabetically(
            this.teamMembersToDisplay.filter((teamMember: Participant) =>
              `${teamMember.firstName} ${teamMember.lastName}`.toLocaleLowerCase().includes(keywordLowerCase)
            )
          ),
        ]),
      ];
      // eslint-disable-next-line guard-for-in
      for (const data in this.sendbirdParticipants) {
        // eslint-disable-next-line sonarjs/no-ignored-return
        this.searchedTeamMembersToDisplay.map((searchedData) => {
          if (searchedData.id === this.sendbirdParticipants[data].id) {
            searchedData.DefaultMuteStatus = this.sendbirdParticipants[data].isMuted;
          }
        });
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface, sonarjs/cognitive-complexity
  public ngAfterViewInit(): void {
    this.taggedTeamMembersToDisplay = [];

    if (this.sendbirdParticipants && this.sendbirdParticipants.length > 0) {
      this.existingChannelParticipantsFlag = this.sendbirdParticipants.length > 0 ? true : false;

      this.sendbirdParticipants.forEach((d) => {
        const userCopy = { ...d };
        userCopy.OutOfOffice = false;
        this.taggedTeamMembersToDisplay.push(userCopy);
      });
    }

    this.unsubscribeChannelInfo = this.channelInfoDetail$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((data) => {
      if (data?.users) {
        this.taggedTeamMembersToDisplay = data.users.map((user) => {
          const userCopy = { ...user };
          userCopy.OutOfOffice = false;
          return userCopy;
        });
      }
    });

    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.listOfParticipants$.subscribe((response: AccountParticipantResponse) => {
      this.saveButtonFlagChanges.next(false);

      let tempObj: any;
      let newAcTTecexData: any;
      let newSOTecexData: any;

      if (response.ACTecexPeople || response.SOTecexPeople) {
        if (response.SOTecexPeople === undefined || response.SOTecexPeople.length === 0) {
          newAcTTecexData = response.ACTecexPeople?.map((user: ACTecexPeopleResponseItem) => {
            const newObjForACTecexUsers = {};
            Object.assign(newObjForACTecexUsers, user, { id: user.UserID, firstName: user.FirstName, lastName: user.LastName });
            return newObjForACTecexUsers;
          });
        } else {
          newSOTecexData = response.SOTecexPeople?.map((user: SOTecexPeopleResponseItem) => {
            const newObjForSOTecexUsers = {};
            Object.assign(newObjForSOTecexUsers, user, { id: user.UserID, firstName: user.FirstName, lastName: user.LastName });
            return newObjForSOTecexUsers;
          });
        }

        const newClientUserData = response.ClientUsers?.map((user: ClientUsersResponseItem) => {
          const newObjectForClientUsers = {};
          Object.assign(newObjectForClientUsers, user, {
            id: user.UserID,
            firstName: user.FirstName,
            lastName: user.LastName,
            contactId: user.ContactId,
            roleFlag: 'ClientUsers',
          });
          return newObjectForClientUsers;
        });

        const newClientContactDataForClientUsers = response.ClientContacts?.map((user: ClientContactsResponseItem) => {
          const newObjectForClientContacts = {};
          Object.assign(newObjectForClientContacts, user, { id: user.contactID, firstName: user.Name });
          return newObjectForClientContacts;
        });

        const newThirdPartyUsersData = response.ThirdPartyPeople?.map((user: any) => {
          const newObjForACThirdParty = {};
          Object.assign(newObjForACThirdParty, user, {
            id: user.Id,
            firstName: user.AffiliateUserName,
            nickName: user.AffiliateUserName,
            userEmail: user.ThirdPartyEmail,
          });
          return newObjForACThirdParty;
        });
        this.Internal_Users__c = this.sortInternalUserAlphabetically(newAcTTecexData || newSOTecexData);
        this.Affiliate_Users__c = this.sortThirdPartyUserAlphabetically(newThirdPartyUsersData);
        this.concatClientUserContacts = newClientUserData.concat(newClientContactDataForClientUsers);
        this.Client_Users__c = this.sortClientUserAlphabetically(this.concatClientUserContacts, null);

        this.internal_Users__c_New = this.Internal_Users__c;
        this.client_Users__c_New = this.Client_Users__c;
        this.affiliate_Users__c_New = this.Affiliate_Users__c;

        this.teamMembersToDisplay = this.internal_Users__c_New.concat(this.client_Users__c_New, this.affiliate_Users__c_New);

        // For existing participants selection from channel for ACC : Start
        if (
          (this.sendbirdParticipants && this.sendbirdParticipants.length > 0) ||
          (this.taggedTeamMembersToDisplay && this.taggedTeamMembersToDisplay.length > 0)
        ) {
          this.taggedTeamMembersToDisplay.forEach((existingData) => {
            //We need to assess of these users are out of office for the display
            const internalUserDetail = this.Internal_Users__c.filter((internalUser) => internalUser.id === existingData.id);
            if (internalUserDetail.length === 1) {
              const index: number = this.Internal_Users__c.findIndex((x) => x.id === existingData.id);
              this.Internal_Users__c.splice(index, 1);
              if (this.Internal_Users__c.find((x) => x.id === existingData.id))
                this.remove.emit(this.Internal_Users__c.find((x) => x.id === existingData.id));
            } else if (internalUserDetail.length > 1) {
              // eslint-disable-next-line sonarjs/no-ignored-return
              const remainingParticipantsFromInternalUser = this.Internal_Users__c.filter(
                (obj: any) => !internalUserDetail.some((obj2: any) => obj.UserID === obj2.UserID)
              );

              this.Internal_Users__c = remainingParticipantsFromInternalUser;

              remainingParticipantsFromInternalUser.forEach((element) => {
                this.remove.emit(element);
              });
            }

            let indexFromClientUsersAgain;
            let indexFromClientUsersAgain1;

            if (existingData.contactId || existingData.ContactId || existingData.contactID) {
              indexFromClientUsersAgain = this.Client_Users__c.findIndex(
                (y) => y.ContactId === (existingData.contactId || existingData.ContactId || existingData.contactID)
              );
              if (indexFromClientUsersAgain !== -1) {
                this.Client_Users__c.splice(indexFromClientUsersAgain, 1);
              }

              indexFromClientUsersAgain1 = this.Client_Users__c.findIndex(
                (y) => y.contactID === (existingData.contactId || existingData.ContactId || existingData.contactID)
              );
              if (indexFromClientUsersAgain1 !== -1) {
                this.Client_Users__c.splice(indexFromClientUsersAgain1, 1);
              }

              this.Client_Users__c.forEach((element) => {
                this.remove.emit(element);
              });
            }

            const indexFromClientUsers = this.Client_Users__c.findIndex((y) => y.id === existingData.id);
            if (
              (indexFromClientUsersAgain === -1 ||
                indexFromClientUsersAgain === undefined ||
                indexFromClientUsersAgain1 === -1 ||
                indexFromClientUsersAgain1 === undefined) &&
              indexFromClientUsers !== -1
            ) {
              this.Client_Users__c.splice(indexFromClientUsers, 1);

              this.Client_Users__c.forEach((element) => {
                this.remove.emit(element);
              });
            }

            const indexFromAffiliate_Users__c = this.Affiliate_Users__c.findIndex((y) => y.id === existingData.id);
            if (indexFromAffiliate_Users__c !== -1) {
              this.Affiliate_Users__c.splice(indexFromAffiliate_Users__c, 1);

              this.Affiliate_Users__c.forEach((element) => {
                this.remove.emit(element);
              });
            }
          });

          const guestUserDetail = this.taggedTeamMembersToDisplay.filter((x) => x.userType === 'Guest');

          if (this.taggedTeamMembersToDisplay.length > 0 && guestUserDetail) {
            this.listOfGuestUsers = guestUserDetail;
          }

          this.taggedTeamMembersToDisplay = this.taggedTeamMembersToDisplay.filter((x) => x.userType !== 'Guest');
          if (this.taggedTeamMembersToDisplay) {
            const allUsers = response.ACTecexPeople.concat(
              response.AllTecexPeople,
              response.SOTecexPeople,
              response.ThirdPartyPeople,
              // @ts-ignore
              response.ClientUsers
            );

            this.taggedTeamMembersToDisplay.forEach((taggedUser) => {
              const allTaggedUserInfo = allUsers.find((user) => user?.UserID && user.UserID === taggedUser.id);
              if (allTaggedUserInfo) {
                taggedUser.OutOfOffice = allTaggedUserInfo.OutOfOffice;
                taggedUser.OutOfOfficeStartDate = allTaggedUserInfo.OutOfOfficeStartDate;
                taggedUser.OutOfOfficeEndDate = allTaggedUserInfo.OutOfOfficeEndDate;
              }
            });
          }
          this.taggedTeamMembersList.emit(this.taggedTeamMembersToDisplay);
        }
        // For existing participants selection from channel for ACC : End

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.sendbirdParticipants && this.sendbirdParticipants.length === 0 ? (this.taggedTeamMembersToDisplay = []) : null;

        // Start of new tagging logic
        //Steps:
        // 1 Find ids of users with correct roles in Internal_Users__c
        // 2 Push these users in taggedTeamMembersToDisplay and remove them from Internal_Users__c
        // 3 Remove users from the array with same ids, different roles Internal_Users__c

        if (this.taggedTeamMembersToDisplay.length > 0) {
          this.taggedRoles = [];
        }
        // Remove Lead ICE if both ICE and Lead ICE exist
        if (
          this.taggedRoles.includes(MessagesRoles.ICE) &&
          this.taggedRoles.includes(MessagesRoles.LICE) &&
          this.Internal_Users__c.some((person) => person.Role === MessagesRoles.ICE) &&
          this.Internal_Users__c.some((person) => person.Role === MessagesRoles.LICE)
        ) {
          this.taggedRoles = this.taggedRoles.filter((role) => role !== MessagesRoles.LICE);
        }

        this.taggedRoles.forEach((role) => {
          // Get eligible users to tag
          const usersToTag = this.Internal_Users__c.filter((user) => user.Role === role);
          // Need to remove all users with the above ids from Internal_Users__c
          usersToTag.forEach((taggedUser) => {
            this.add.emit(taggedUser);
            this.Internal_Users__c = this.Internal_Users__c.filter((internalUser) => internalUser.id !== taggedUser.id);
          });
          this.taggedTeamMembersToDisplay = this.taggedTeamMembersToDisplay.concat(usersToTag);
        });

        this.taggedTeamMembersList.emit(this.taggedTeamMembersToDisplay);
        // end of new tagging logic

        // For login user selected / tagged in ACC : Start
        let dataFromNewClientUserData;

        if (newAcTTecexData) {
          dataFromNewClientUserData = newAcTTecexData.find((x: ACTecexPeopleResponseItem) => x.UserID === this.loginUserDetail.id);
        } else if (newSOTecexData) {
          dataFromNewClientUserData = newSOTecexData.find((x: SOTecexPeopleResponseItem) => x.UserID === this.loginUserDetail.id);
        }
        const dataFromNewClientUsers = newClientUserData.find((x: ClientUsersResponseItem) => x.UserID === this.loginUserDetail.id);
        const dataFromClientContact = newClientContactDataForClientUsers.find(
          (x: ClientContactsResponseItem) => x.contactID === this.loginUserDetail.id
        );
        const dataFromThirdparty = newThirdPartyUsersData.find((x: any) => x.Id === this.loginUserDetail.id);

        const data = dataFromNewClientUserData || dataFromNewClientUsers || dataFromClientContact || dataFromThirdparty;

        let email;

        if (this.isThirdParty) {
          email = data.userEmail;
        } else {
          email = data.Useremail ? data.Useremail : data.Email;
        }

        if (data) {
          tempObj = {
            firstName: data.FirstName ? data.FirstName : data.firstName,
            id: data.id,
            lastName: data.LastName,
            photoUrl: data.photoUrl ? data.photoUrl : '/profilephoto/005/F',
            roleOfParticipant: data.roleOfParticipant,
            DefaultMuteStatus: data.DefaultMuteStatus,
            Useremail: email,
            nickName: data.Name ? data.Name : data.Username,
            userId: data.UserID,
            contactId: data.ContactId ? data.ContactId : data.contactID,
          };

          if (
            (!this.sendbirdParticipants || this.sendbirdParticipants?.length > 0) &&
            !this.taggedTeamMembersToDisplay.find((x) => x.id === tempObj.id)
          ) {
            const indexOfInternalUser: number = this.Internal_Users__c.findIndex((x) => x.id === tempObj.id);
            const indexOfClientUser: number = this.Client_Users__c.findIndex(
              (x) => x.ContactId === tempObj.contactId || x.contactID === tempObj.contactId
            );
            const indexOfThirdPartyUser: number = this.Affiliate_Users__c.findIndex((x) => x.id === tempObj.id);

            if (indexOfInternalUser !== -1) {
              this.Internal_Users__c.splice(indexOfInternalUser, 1);
            } else if (indexOfClientUser !== -1) {
              this.Client_Users__c.splice(indexOfClientUser, 1);
            } else if (indexOfThirdPartyUser !== -1) {
              this.Affiliate_Users__c.splice(indexOfThirdPartyUser, 1);
            }
            this.taggedTeamMembersToDisplay.push(tempObj);

            this.add.emit(tempObj);
          }
        }
        // For login user selected / tagged in ACC : End

        const listOfACCUsersNew = Object.assign(
          [],
          { ACTecexPeople: response.ACTecexPeople },
          { AllTecexPeople: response.AllTecexPeople },
          { SOTecexPeople: response.SOTecexPeople },
          { ClientUsers: response.ClientUsers },
          { ClientContacts: response.ClientContacts },
          { ThirdPartyPeople: response.ThirdPartyPeople }
        );

        this.listOfACCUsers.emit(listOfACCUsersNew);

        this.companyNameForClient = this.Client_Users__c[0].CompanyName ? this.Client_Users__c[0].CompanyName : '';
        this.cdr.detectChanges();

        const indexFromClientUser = this.Client_Users__c.findIndex((x: any) => x.contactID === this.loginUserDetail.contactId);
        const indexFromClientUserAgain = this.Client_Users__c.findIndex((x: any) => x.ContactId === this.loginUserDetail.contactId);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        indexFromClientUser === -1 ? null : this.Client_Users__c.splice(indexFromClientUser, 1);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        indexFromClientUserAgain === -1 ? null : this.Client_Users__c.splice(indexFromClientUserAgain, 1);

        this.concatClientUserContacts = newClientUserData.concat(newClientContactDataForClientUsers);

        this.internal_Users__c_New = this.sortInternalUserAlphabetically(newAcTTecexData || newSOTecexData);
        this.client_Users__c_New = this.sortClientUserAlphabetically(this.concatClientUserContacts, null);
        this.affiliate_Users__c_New = this.sortThirdPartyUserAlphabetically(newThirdPartyUsersData);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public isAdded(teamMember: any): boolean {
    if (this.sendbirdParticipants && this.sendbirdParticipants.length > 0) {
      return this.sendbirdParticipants?.some((participants) => participants.id === teamMember.id);
    } else if (this.taggedTeamMembersToDisplay && this.taggedTeamMembersToDisplay.length > 0) {
      return this.taggedTeamMembersToDisplay?.some((participants) => participants.id === teamMember.id);
    } else {
      return this.participants.some((item) => item.id === teamMember.id);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public isRemovable(teamMember: any): boolean {
    const participant = this.sendbirdParticipants
      ? this.sendbirdParticipants?.find((item) => item.id === teamMember.id && teamMember.id !== this.loginUserDetail.id)
      : this.taggedTeamMembersToDisplay.find((item) => item.id === teamMember.id && teamMember.id !== this.loginUserDetail.id);

    if (participant === undefined) {
      return false;
    }

    return (this.sendbirdParticipants && this.sendbirdParticipants.length > 0 && this.lengthOfMessageForUpdateParticipants > 0) ||
      (this.taggedTeamMembersToDisplay && this.taggedTeamMembersToDisplay.length > 0 && this.lengthOfMessageForUpdateParticipants > 0)
      ? false
      : true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public onAdd(teamMember: any): void {
    this.saveButtonFlagChanges.next(true);

    const internalUserDetail = this.internal_Users__c_New?.filter((internalUser) => internalUser.id === teamMember.id);
    if (internalUserDetail.length === 1) {
      const index: number = this.Internal_Users__c.findIndex((x) => x.id === teamMember.id);
      this.Internal_Users__c.splice(index, 1);
      teamMember.isNewFlag = true;
      this.taggedTeamMembersToDisplay.push(teamMember);
    } else if (internalUserDetail.length > 1) {
      const remainingParticipants = this.Internal_Users__c.filter(
        (obj: any) => !internalUserDetail.some((obj2: any) => obj.UserID === obj2.UserID)
      );

      this.Internal_Users__c = remainingParticipants;
      teamMember.isNewFlag = true;
      this.taggedTeamMembersToDisplay.push(teamMember);
    }

    const clientUsersDetail =
      teamMember && (teamMember.contactID || teamMember.contactId)
        ? this.client_Users__c_New?.find((clientUser) => clientUser.ContactId === (teamMember.contactID || teamMember.contactId))
        : null;
    if (clientUsersDetail) {
      const index: number = this.Client_Users__c.findIndex((x) => x.ContactId === (teamMember.contactID || teamMember.contactId));
      this.Client_Users__c.splice(index, 1);
      teamMember.isNewFlag = true;
      this.taggedTeamMembersToDisplay.push(teamMember);
    }

    const clientUsersDetailAgain =
      teamMember && (teamMember.contactID || teamMember.contactId)
        ? this.client_Users__c_New?.find((clientUser) => clientUser.contactID === (teamMember.contactID || teamMember.contactId))
        : null;
    if (clientUsersDetailAgain) {
      const index: number = this.Client_Users__c.findIndex((x) => x.contactID === (teamMember.contactID || teamMember.contactId));
      this.Client_Users__c.splice(index, 1);
      if (!clientUsersDetail) {
        teamMember.isNewFlag = true;
        this.taggedTeamMembersToDisplay.push(teamMember);
      }
    }

    const thirdPartyUserDetail = this.affiliate_Users__c_New?.filter((affiliateUser) => affiliateUser.id === teamMember.id);
    if (thirdPartyUserDetail.length === 1) {
      const index: number = this.Affiliate_Users__c.findIndex((x) => x.id === teamMember.id);
      this.Affiliate_Users__c.splice(index, 1);
      teamMember.isNewFlag = true;
      this.taggedTeamMembersToDisplay.push(teamMember);
    } else if (thirdPartyUserDetail.length > 1) {
      const remainingParticipants = this.Affiliate_Users__c.filter(
        (obj: any) => !internalUserDetail.some((obj2: any) => obj.UserID === obj2.UserID)
      );

      this.Affiliate_Users__c = remainingParticipants;
      teamMember.isNewFlag = true;
      this.taggedTeamMembersToDisplay.push(teamMember);
    }

    this.taggedTeamMembersList.emit(this.taggedTeamMembersToDisplay);
    this.add.emit(teamMember);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public onRemove(teamMember: any): void {
    this.saveButtonFlagChanges.next(true);

    const tempData = this.internal_Users__c_New?.filter((internalUser) => internalUser.UserID === teamMember.id);
    if (tempData.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      teamMember.isNewFlag ? (teamMember.isNewFlag = false) : null;
      tempData.forEach((elementOfTempData) => {
        this.Internal_Users__c.push(elementOfTempData);
      });
      this.Internal_Users__c = this.sortInternalUserAlphabetically(this.Internal_Users__c);
    }

    const tempData2 =
      teamMember.contactID || teamMember.contactId
        ? this.client_Users__c_New?.filter(
            (clientUser) =>
              clientUser.ContactId === (teamMember.contactID || teamMember.contactId) ||
              clientUser.contactID === (teamMember.contactID || teamMember.contactId)
          )
        : null;
    if (tempData2 && tempData2.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      teamMember.isNewFlag ? (teamMember.isNewFlag = false) : null;
      tempData2.forEach((elementOfTempData1) => {
        this.Client_Users__c.push(elementOfTempData1);
      });
      this.Client_Users__c = this.sortClientUserAlphabetically(this.Client_Users__c, null);
    }

    const tempData1 = this.client_Users__c_New?.filter((clientUser) => clientUser.id === teamMember.id);
    if (tempData2 && tempData2.length === 0 && tempData1.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      teamMember.isNewFlag ? (teamMember.isNewFlag = false) : null;
      tempData1.forEach((elementOfTempData1) => {
        this.Client_Users__c.push(elementOfTempData1);
      });
      this.Client_Users__c = this.sortClientUserAlphabetically(this.Client_Users__c, null);
    }

    const tempData3 = this.affiliate_Users__c_New?.filter((affiliateUser) => affiliateUser.id === teamMember.id);
    if (tempData3.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      teamMember.isNewFlag ? (teamMember.isNewFlag = false) : null;
      tempData3.forEach((elementOfTempData3) => {
        this.Affiliate_Users__c.push(elementOfTempData3);
      });
      this.Affiliate_Users__c = this.sortThirdPartyUserAlphabetically(this.Affiliate_Users__c);
    }

    const taggedSameParticipantDetail = this.taggedTeamMembersToDisplay.filter((x) => x.id === teamMember.id);

    taggedSameParticipantDetail.forEach((element) => {
      const index: number = this.taggedTeamMembersToDisplay.findIndex((x) => x.id === element.id);
      this.taggedTeamMembersToDisplay.splice(index, 1);
    });

    this.taggedTeamMembersList.emit(this.taggedTeamMembersToDisplay);
    this.remove.emit(teamMember);
  }

  public onCloseSearchClick(): void {
    this.searchFieldControl.setValue('');
  }

  public onCancleClick(): void {
    this.cancelControl.setValue('');
  }

  private sortParticipantsAlphabetically(participants: Participant[]) {
    const sortedParticipants = [...participants];

    sortedParticipants.sort((a, b) => {
      if (a.firstName?.localeCompare(b.firstName) === 0) {
        return a.lastName?.localeCompare(b.lastName);
      }

      return a.firstName?.localeCompare(b.firstName);
    });

    return sortedParticipants;
  }

  private sortInternalUserAlphabetically(internalUsers: any): InternalUser[] {
    const sortedInternalUsers = [...internalUsers];

    sortedInternalUsers.sort((a, b) => {
      if (a.FirstName?.localeCompare(b.FirstName) === 0) {
        return a.FirstName?.localeCompare(b.FirstName);
      }

      return a.FirstName?.localeCompare(b.FirstName);
    });
    return sortedInternalUsers;
  }

  private sortClientUserAlphabetically(clientUser: any, contactUser: any) {
    let sortedClientUser = [...clientUser];
    if (contactUser) {
      sortedClientUser = [...clientUser, ...contactUser];
    }
    sortedClientUser.sort((a, b) => {
      if (a.firstName?.localeCompare(b.firstName) === 0) {
        return a.name?.localeCompare(b.name);
      }

      return a.firstName?.localeCompare(b.firstName);
    });

    return sortedClientUser;
  }

  private sortThirdPartyUserAlphabetically(thirdPartyUsers: any) {
    const sortedParticipants = [...thirdPartyUsers];

    // Sort : By Third Party Company name wise :
    sortedParticipants.sort((a, b) => {
      if (a.ThirdPartyCompany?.localeCompare(b.ThirdPartyCompany) === 0) {
        return a.name?.localeCompare(b.name);
      }

      return a.ThirdPartyCompany?.localeCompare(b.ThirdPartyCompany);
    });

    return sortedParticipants;
  }

  // For guest user toggle change :
  public guestUserToggleChange(value: boolean): void {
    this.guestUserToggle = !value;
    this.guestUserValueChange.next(this.guestUserToggle);
  }

  public ngOnDestroy(): void {
    // For unsubscribe the subscribe from ng-afterViewInit, it's necessary :
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  protected readonly SourceApp = SourceApp;
}
