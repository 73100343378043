import { Inject, Injectable, Injector } from '@angular/core';
import { Configuration, ProfileDataService, UserDetailsResponseClientUserIds } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { BehaviorSubject, EMPTY, Observable, Subject, switchMap } from 'rxjs';
import { catchError, first, map, mapTo, tap } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { redirectUrlSessionStorageKey } from '../constants/global.constants';
import { AuthCredentials } from '../interfaces/auth-credentials.interface';
import { User } from '../interfaces/user.interface';
import { LoadingIndicatorService } from '../modules/loading-indicator/services/loading-indicator.service';
import { TokenConfigService } from './token-config.service';

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'accessToken';
const USER_ID_LOCAL_STORAGE_KEY = 'userId';
const MAINTENANCE_SESSION_STORAGE_KEY = 'maintenanceSession';
const ROLL_OUT_QUOTE_SUMMARY = 'Roll_out_Quote_Summary';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly user$: BehaviorSubject<User> = new BehaviorSubject(undefined);

  public readonly logout$: Subject<void> = new Subject();

  constructor(
    private readonly injector: Injector,
    private readonly tokenConfigService: TokenConfigService,

    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig
  ) {}

  public loadProfile$(): Observable<boolean> {
    const loadingIndicatorService = this.injector.get<LoadingIndicatorService>(LoadingIndicatorService);

    loadingIndicatorService.open();

    return this.updateProfile$().pipe(
      tap(() => loadingIndicatorService.dispose()),
      mapTo(true),
      catchError((error) => {
        // NOTE: this is a hackish way of deciding if the saved access token is expired
        //   - the 401 response coming from the server does not contain CORS headers
        //   - thus a general error handling is introduced for the GetUserProfile call
        //   - you cannot detect CORS errors specifically (for security purposes)
        //   - this error handler can falsely be called when e.g. internet is down

        // eslint-disable-next-line no-console
        console.error('Failed to load user profile', error);

        this.logout();

        return EMPTY;
      })
    );
  }

  public updateProfile$(): Observable<void> {
    const profileDataService = this.injector.get<ProfileDataService>(ProfileDataService);

    return profileDataService.getUserDetails(this.getCredentials().userId).pipe(
      map((response) => response.ClientUserIds[0]),
      map<UserDetailsResponseClientUserIds, User>((data) => {
        return {
          accessToken: atob(data.AccessToken),
          id: data.ClientUserID,
          contactId: data.ClientContactId,
          accountId: data.ClientAccID,
          accountManager: {
            id: data.AccountManagerID,
            name: data.AccountManagerName,
            profilePicture: data.AccountManagerProfilepic,
          },
          financialController: {
            id: data.FinancialControllerId,
            name: data.FinancialControllerName,
            profilePicture: data.FinancialControllerProfilePic,
          },
          name: data.loginContactName,
          email: data.loginContactEmail,
          profilePicture: data.loginContactphotourl,
          accountName: data.AccountName,
          isVetted: data.vettedaccount,
          contractSigned: data.Contractsigned,
          vettingStatus: data.ClientVettingStatus,
          allowCustomizedFinalDeliveries: data.AllowCustomisedFinalDeliveries,
          freightPreference: data.Freightpreference,
          ncpPermission:
            data.NCPPermission === 'All' ? 'Quote Creation;Quote Acceptance;Task Completion;Invoice Access' : data.NCPPermission,
          superUser: data.SuperUser,
          orgId: data.OrgId,
          recordTypeId: data.recordTypeId,
          recordTypeName: data.recordTypeName,
          quoteGuidanceOptIn: data.QuoteGuidanceOptIn,
          outOfOffice: data.Out_Of_Office__c,
          oooMessage: data.OOO_Message__c,
          oooStartDate: data.OOO_Start_Date__c,
          oooEndDate: data.OOO_End_Date__c,
          standByUser: data.Stand_by_user__c,
          latestTermsAccepted: data.LatestTCAccepted,
        };
      }),
      switchMap((user) => {
        this.user$.next(user);
        return this.tokenConfigService.updateTokens$(user);
      }),
      // eslint-disable-next-line unicorn/no-useless-undefined
      mapTo(undefined)
    );
  }

  public getUser$(): Observable<User> {
    return this.user$.asObservable().pipe(first((user) => !isNil(user)));
  }

  public login(): void {
    sessionStorage.setItem(redirectUrlSessionStorageKey, `${window.location.pathname}${window.location.hash}`);
    window.location.href = this.config.loginRedirectUrl;
  }

  public logout(): void {
    this.setCredentials(undefined, false);
    this.logout$.next();

    // Remove the roll-out quote summary detail :
    localStorage.removeItem(ROLL_OUT_QUOTE_SUMMARY);

    window.location.href = this.config.logoutRedirectUrl;
  }

  public setCredentials(credentials: AuthCredentials, isUnderMaintenance: boolean): void {
    const apiConfig = this.injector.get<Configuration>(Configuration);

    if (!credentials) {
      localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(USER_ID_LOCAL_STORAGE_KEY);

      return;
    }

    // save to local storage
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, credentials.accessToken);
    localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, credentials.userId);

    // set token on data package
    apiConfig.accessToken = credentials.accessToken;

    //Leaving old Maintenance logic commented out for now
    // If the login happened during maintenance we mark the session,
    // so the user can actually do things without being blocked by the mainteance dialog.
    // This is a feature only for admins. This feature relies on regular users not being able to login during maintenance.
    if (!isUnderMaintenance) {
      localStorage.removeItem(MAINTENANCE_SESSION_STORAGE_KEY);
    }
    // } else {
    //   sessionStorage.removeItem(MAINTENANCE_SESSION_STORAGE_KEY);
    // }
  }

  public getCredentials(): AuthCredentials {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    const userId = localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY);

    if (!accessToken || !userId) {
      return undefined;
    }

    return {
      accessToken,
      userId,
    };
  }

  public isMaintenanceSession(): boolean {
    try {
      return JSON.parse(localStorage.getItem(MAINTENANCE_SESSION_STORAGE_KEY));
    } catch {
      return false;
    }
  }

  // For third-party flag value getting :
  public thirdPartyFlag(): any {
    let userData: any;

    this.getUser$().subscribe((userDetail): any => {
      userData = userDetail ? userDetail : null;
    });

    return userData.recordTypeId === '01207000000Xf2nAAC';
  }
}
