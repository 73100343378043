import { PickupPreference, ShipmentOrderRelationsFreightDetails, UpdateAddress } from '@tecex-api/data/model/models';

export const mapUpdatePickupReferenceHelper = (
  pickupPreference: PickupPreference,
  freightDetails: ShipmentOrderRelationsFreightDetails
): UpdateAddress => ({
  ID: freightDetails.PickupaddId,
  DefaultAddress: freightDetails.isDefault,
  Name: freightDetails.AddressName,
  PickupPreference: pickupPreference,
  Contact_Full_Name: freightDetails.ContactName,
  Contact_Email: freightDetails.ContactEmail,
  Contact_Phone_Number: freightDetails.ContactPhone,
  AddressLine1: freightDetails.AddressLine1,
  AddressLine2: freightDetails.AddressLine2,
  City: freightDetails.City,
  Province: freightDetails.StateProvince,
  Postal_Code: freightDetails.Zip,
  All_Countries: freightDetails.Country,
  CompanyName: freightDetails.CompanyName,
});
