import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MiscDataService } from '@tecex-api/data';
import { Subject } from 'rxjs';
import { AuthService } from '../../../../services/auth.service';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { Announcment } from '../../types/announcment.type';
import { CONFIG_TOKEN } from '../../../../config/config.token';
import { GlobalConfig } from '../../../../config/global-config.interface';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { AnnouncmentService } from '../../services/announcment.service';
import { DialogService } from '../../../dialog/dialog.service';
@Component({
  selector: 'terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, NgIf, MatButtonModule, DatePipe, TranslateModule],
})
export class TermsAndConditionsDialogComponent {
  public readonly announcment: Announcment;

  public readonly imgPath = `assets/icons/dark_logo.svg`;
  private readonly destroyed$ = new Subject<void>();
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    @Inject(DIALOG_DATA) private readonly data: DialogData<any>,
    private readonly authService: AuthService,
    private readonly miscDataService: MiscDataService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly announcementService: AnnouncmentService,
    private readonly dialogService: DialogService
  ) {}
  public readonly termLink = this.config.termsAndConditionsUrl;
  public readonly privacyLink = this.config.privacyPolicyUrl;
  public onDeclineClick(): void {
    this.announcementService.rejectTerms$().subscribe(() => {});
  }

  public onAcceptClick() {
    this.announcementService.acceptTerms$().subscribe(() => {
      this.data.dialogRef.close();
      this.dialogService.closeAll();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
